/* eslint-disable */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import AudioPlayer from "../../../components/audioExercice/audioPlayer";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
import _, { set } from "lodash";
import {
  Box,
  Input,
  Card,
  CardContent,
  makeStyles,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  SvgIcon,
  IconButton,
  Popover,
  Slider,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  ListItemText,
  AccordionSummary,
  CircularProgress,
  Accordion,
  AccordionDetails,
  FormLabel,
  FormGroup,
  FormHelperText,
  Avatar,
  RadioGroup,
  Radio,
  Tab,
  Tabs,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import CheckIcon from "@material-ui/icons/Check";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MicIcon from "@material-ui/icons/Mic";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddIcon from "@material-ui/icons/Add";
import UpdateIcon from "@material-ui/icons/Update";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import BarChartIcon from "@material-ui/icons/BarChart";
import ReplayIcon from "@material-ui/icons/Replay";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";
import ReportIcon from "@material-ui/icons/Report";
import FolderIcon from "@material-ui/icons/Folder";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ListIcon from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Prompt } from "react-router-dom";
import getInitials from "../../../utils/getInitials";
import data from "./data";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr.js";
import typography from "../../../theme/typography";
import "./TrainingProgram.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Mic } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
moment.locale("fr");

function weeksInYear(year) {
  return Math.max(
    moment(new Date(year, 11, 31)).isoWeek(),
    moment(new Date(year, 11, 31 - 7)).isoWeek()
  );
}

const trainingImages = [
  "/trainings/balance-young-muscular-caucasian-woman-practicing-in-gym-with-equipment-athletic-female-model-doing-speed-exercises-training-her-hands-chest-upper-body-wellness-healthy-lifestyle-bodybuilding-min.jpg",
  "/trainings/fit-young-woman-lifting-barbells-looking-focused-working-out-gym-min.jpg",
  "/trainings/medium-shot-woman-training-at-home-min.jpg",
  "/trainings/muscular-male-athlete-doing-workout-at-the-park-gymnastics-training-fitness-workout-flexibility-summer-city-in-sunny-day-active-and-healthy-lifestyle-youth-bodybuilding-min.jpg",
  "/trainings/muscular-male-athlete-doing-workout-at-the-park-gymnastics-training-fitness-workout-flexibility-summer-city-in-sunny-day-on-background-field-active-and-healthy-lifestyle-youth-bodybuilding-min.jpg",
  "/trainings/outdoor-view-relaxed-african-american-man-with-dark-healthy-skin-drinks-water-sits-hill-ejoys-beautiful-landscape-calm-atmopshere-mountains-dressed-clothes-sport-wellness-min.jpg",
  "/trainings/sportswoman-sportswear-training-gym-min.jpg",
  "/trainings/strong-man-exercising-at-the-gym-min.jpg",
  "/trainings/young-athletic-woman-doing-her-exercise-routine-at-home-min.jpg",
  "/trainings/young-healthy-man-athlete-doing-balance-exercises-squats-gym-single-model-practicing-hard-training-his-lower-body-concept-healthy-lifestyle-sport-fitness-bodybuilding-wellbeing-min.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_246967226.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_394335814.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fattractive-fit-man-working-out-indoors-with-dumbbells.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fbodybuilder-training-arm-with-resistance-band.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fclose-up-on-man-doing-crossfit-workout.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fclose-up-on-woman-doing-crossfit-workout.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffemale-fitness-trainer-at-the-gym.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FFemme-Korper.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FFitness%20-%20korper%201.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FFitness%20women.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffull-shot-man-training-with-ball.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffull-shot-sporty-man-exercising.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffull-shot-woman-holding-dumbbell.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffull-shot-woman-training-with-elastic-band.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fheavy-powerlifting-in-gym.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fmedium-shot-woman-running%20(1).jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fmedium-shot-woman-training-gym.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-andrea-piacquadio-3775164.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-anete-lusina-4793212.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-elina-fairytale-3823063.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-gustavo-fring-4148956.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-karl-solano-2780762.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-ketut-subiyanto-4804027-2.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-ketut-subiyanto-4804337.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-li-sun-2294361.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-maksim-goncharenok-4348640.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-mart-production-8032778.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-mart-production-8032785.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-mart-production-8033014.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-marta-wave-6454196.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-miriam-alonso-7592354.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-pavel-danilyuk-6339644.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-rodnae-productions-8401171.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fpexels-rodnae-productions-8402214.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fstrong-active-curly-dark-skinned-man-in-black-shorts-and-white-long-sleeved-t-shirt-squats-works-out-and-stretches-near-sea.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fstrong-man-training-in-gym.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fwoman-holding-rope-in-gym.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fwoman-training-at-gym-full-shot.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FWorkout-Korper-Men.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FWorkout-Korper-Men2.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FWorkout-Korper-Women.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fyoung-adult-doing-outdoor-fitness.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fyoung-bodybuilder-running-cardio-workout-and-looking-at-the-gym-window.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fyoung-fitness-woman-runner.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fyoung-woman-doing-her-workout-at-home-on-fitness-mat.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fyoung-woman-with-fit-body-jumping-and-running-against-grey-wall.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_251454805.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_279359756.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_301200075.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_455579672.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_339220620.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_497269373.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_286120595.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_113813657.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2FAdobeStock_303279321.jpeg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fclose-up-on-woman-doing-crossfit-workout.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Fback-view-of-female-athlete-jumping-up-the-stairs-while-exercising-outdoors.jpg",
  "/redirect/https%3A%2F%2Fkorperonline.b-cdn.net%2Ftraining-preview%2Ffull-shot-man-doing-burpees.jpg",
];

const metTable = {
  Cardio: 10.7,
  Musculation: 7.1,
  "Cross-Training": 10.7,
  "Poids de corps": 9.5,
  "Warm-up": 3,
  Mobilité: 3,
  Récupération: 3,
  Repos: 2,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    // marginLeft: '20px',
    width: "410px",
    flexShrink: 0,
    borderRadius: 0,
    scrollSnapAlign: "start",
    paddingTop: "6px",
    position: "relative",
  },
  cancelBtn: {
    backgroundColor: "white",
    color: "black",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "#0000001f",
    },
  },
  deleteBtn: {
    backgroundColor: "red",
    color: "white",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  formDimmer: {
    position: "sticky",
    top: 0,
    width: "100%",
    height: "100vh",
    marginTop: "-100vh",
    marginRight: "2px",
    zIndex: 8,
    background: "rgb(58 58 58 / 30%)",
  },
  exerciceTimeInput: {
    width: "80px",
    fontSize: "13px",
  },
  exerciceNumberInput: {
    fontSize: "13px",
  },
  smallLabel: {
    fontSize: "13px",
  },
  timeLabel: {
    fontSize: "13px",
    opacity: 0,
  },
  timeLabelFocused: {
    opacity: 1,
  },
}));

const TemplateModal = (props) => {
  const { handleClose, open, onImport } = props;
  const [templates, setAllTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("none");

  let disabled = !selectedTemplate || selectedTemplate === "none";

  const getAllTemplates = async () => {
    try {
      let response = await axios.get(
        `https://online.korper.io/coachs/templates`,
        { withCredentials: true }
      );

      if (response.data.success) {
        setAllTemplates(response.data.templates);
      } else {
        toast.error("Erreur serveur, impossible de récupurer les modèles");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, impossible de récupurer les modèles");
    }
  };

  const getChoosenTemplate = async (id) => {
    try {
      let response = await axios.get(
        `https://online.korper.io/coachs/templates/${id}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        onImport(response.data.template);
        handleClose();
      } else {
        toast.error("Erreur serveur, impossible de récupurer les modèles");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, impossible de récupurer les modèles");
    }
  };

  useEffect(() => {
    if (open === true) {
      setSelectedTemplate("none");
      setAllTemplates(null);
      getAllTemplates();
    }
  }, [open]);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const onSelect = () => {
    getChoosenTemplate(selectedTemplate);
  };
  const handleChange = (id) => {
    setSelectedTemplate(id);
  };
  const onDelete = async () => {
    try {
      let response = await axios.delete(
        `https://online.korper.io/coachs/template/${selectedTemplate}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        getAllTemplates();
        setSelectedTemplate("none");
      } else {
        toast.error("Erreur serveur, impossible de supprimer ce modèle");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, impossible de supprimer ce modèle");
    }
  };

  //CHANGE
  const [expanded, setExpanded] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState([]);
  const [filterEquipement, setFilterEquipement] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopup = Boolean(anchorEl);
  const [combinedFilters, setCombinedFilters] = useState([]);
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const filteredTemplates = templates?.filter((template) => {
    let result = true;
    if (search) {
      let txt = new RegExp(search, "i");
      result =
        template?.name?.match(txt) ||
        template?.title?.match(txt) ||
        template?.subtitle?.match(txt);
    }
    if (result && filterEquipement?.length) {
      result = !!filterEquipement.every((f) => template.equipment.includes(f));
    }
    return result;
  });

  const filteredCustomers = props.students?.filter((customer) => {
    const txt = new RegExp(search, "i");
    const matchesNameOrTitleOrSubtitle = templates?.some(
      (template) =>
        (template.name && template.name.match(txt)) ||
        (template.title && template.title.match(txt)) ||
        (template.subtitle && template.subtitle.match(txt))
    );
    return matchesNameOrTitleOrSubtitle;
  });

  const convertirTemps = (totalTime) => {
    const minutes = Math.floor(totalTime / 60);
    const heures = Math.floor(minutes / 60);

    if (minutes < 60) {
      return `${minutes} min`;
    } else {
      const minutesRestantes = minutes % 60;
      return `${heures} h ${minutesRestantes} min`;
    }
  };

  useEffect(() => {
    setCombinedFilters([...filterType, ...filterEquipement]);
  }, [filterType, filterEquipement]);

  const handleFilterEquipementCheck = (value) => (e) => {
    if (e.target.checked) {
      setFilterEquipement([...filterEquipement, value]);
    } else {
      setFilterEquipement(filterEquipement.filter((v) => v !== value));
    }
  };

  const handleDeleteChip = (valueToDelete) => () => {
    setCombinedFilters((prevCombined) =>
      prevCombined.filter((value) => value !== valueToDelete)
    );
    setFilterType((prevFilters) =>
      prevFilters.filter((value) => value !== valueToDelete)
    );
    setFilterEquipement((prevFilters) =>
      prevFilters.filter((value) => value !== valueToDelete)
    );
  };
  const handleClosePopup = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleClickPopup = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="trainingModal">
        <div className="traningBox">
          <p className="pl-15" style={{ fontWeight: "500" }}>
            Sélectionner un template
          </p>
          <div
            className="flex wrapper"
            style={{ maxWidth: "100%", width: "100%", flexDirection: "column" }}
          >
            <div style={{ marginTop: "15px" }}>
              <TextField
                style={{
                  marginTop: "1px",
                  backgroundColor: "white",
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" style={{ color: "#3888FF" }}>
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Rechercher"
                variant="outlined"
                value={search}
                onChange={(e) => [setSearch(e.target.value)]}
                autoFocus
              />
            </div>
            <div className="wrappertrainingTabs" style={{ marginTop: "15px" }}>
              <div className="optionsfilter-wrap">
                <div
                  className={openPopup && "focus"}
                  onClick={handleClickPopup}
                >
                  {combinedFilters.length ? (
                    <div>
                      {combinedFilters.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={handleDeleteChip(value)}
                          deleteIcon={<CloseIcon style={{ color: "white" }} />}
                          style={{
                            color: "white",
                            backgroundColor: "#3888FF",
                            marginRight: "2px",
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    <p>Sélectionner ...</p>
                  )}

                  {openPopup ? (
                    <ExpandLessIcon></ExpandLessIcon>
                  ) : (
                    <ExpandMoreIcon></ExpandMoreIcon>
                  )}
                </div>
                <Popover
                  className="optionsfilter-popup"
                  open={openPopup}
                  anchorEl={anchorEl}
                  onClose={handleClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ul>
                    <li class="optgroup">
                      <span class="label">Équipement</span>
                      <ul class="optgroup-col">
                        <li>
                          <label>
                            <Checkbox
                              value="Haltères"
                              title="Haltères"
                              checked={combinedFilters.includes("Haltères")}
                              onChange={handleFilterEquipementCheck("Haltères")}
                            />
                            Haltères
                          </label>
                        </li>
                        <li>
                          <label>
                            <Checkbox
                              value="Kettlebell"
                              title="Kettlebell"
                              checked={combinedFilters.includes("Kettlebell")}
                              onChange={handleFilterEquipementCheck(
                                "Kettlebell"
                              )}
                            />
                            Kettlebell
                          </label>
                        </li>
                        <li>
                          <label>
                            <Checkbox
                              value="Barre"
                              title="Barre"
                              checked={combinedFilters.includes("Barre")}
                              onChange={handleFilterEquipementCheck("Barre")}
                            />
                            Barre
                          </label>
                        </li>
                        <li>
                          <label>
                            <Checkbox
                              value="Banc"
                              title="Banc"
                              checked={combinedFilters.includes("Banc")}
                              onChange={handleFilterEquipementCheck("Banc")}
                            />
                            Banc
                          </label>
                        </li>
                        <li>
                          <label>
                            <Checkbox
                              value="Élastique"
                              title="Élastique"
                              checked={combinedFilters.includes("Élastique")}
                              onChange={handleFilterEquipementCheck(
                                "Élastique"
                              )}
                            />
                            Élastique
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Popover>
              </div>
              <ul className="trainingTabs-nav">
                <li
                  className={activeTab === 0 ? "trainingTab-active" : ""}
                  onClick={() => handleTabClick(0)}
                >
                  <FolderIcon />
                  {window.matchMedia("(max-width: 1028px)").matches ? null : (
                    <>
                      <a>Mes templates </a>
                      <p className="selectExo-counter">
                        {filteredTemplates?.length}
                      </p>
                    </>
                  )}
                </li>
                <li
                  className={activeTab === 1 ? "trainingTab-active" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  <GroupIcon />
                  {window.matchMedia("(max-width: 1028px)").matches ? null : (
                    <>
                      {" "}
                      <a>Users </a>
                      <p className="selectExo-counter">
                        {
                          filteredTemplates?.filter(
                            (template) => template.customer
                          ).length
                        }
                      </p>
                    </>
                  )}
                </li>
                <li
                  className={activeTab === 2 ? "trainingTab-active" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  <PersonIcon />
                  {window.matchMedia("(max-width: 1028px)").matches ? null : (
                    <>
                      <a>{props.selectedCustomer.firstName}</a>
                      <p className="selectExo-counter">
                        {
                          filteredTemplates?.filter(
                            (template) =>
                              template.customer === props.selectedCustomer._id
                          ).length
                        }
                      </p>
                    </>
                  )}
                </li>
              </ul>
              <div className="tabs-stage">
                {activeTab === 0 && (
                  <div id="tab-1" className="tab-content">
                    <div
                      class="list"
                      id="style-1"
                      style={{ paddingBottom: "70px" }}
                    >
                      <ul>
                        {filteredTemplates ? (
                          filteredTemplates.map((v) => {
                            return (
                              <li
                                className={
                                  v._id === selectedTemplate &&
                                  "choosenTemplate"
                                }
                                key={v._id}
                                onClick={() => handleChange(v._id)}
                              >
                                <div
                                  className="user-wrapper"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        width: "60%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div className="nameGroup">
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {v.name || v.title}
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {v.name ? v.title : v.subtitle}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "35%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ maxWidth: "80px" }}>
                                        {convertirTemps(v.totalTime)}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "#939393",
                                          }}
                                        >
                                          Date de création
                                        </div>
                                        <div
                                          style={{
                                            fonstSize: "14px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {moment(v.createdAt).calendar({
                                            sameDay: `[Aujourd'hui]`,
                                            nextDay: `[Demain]`,
                                            nextWeek: `dddd`,
                                            lastDay: `[Hier]`,
                                            lastWeek: `dddd [dernier]`,
                                            sameElse: `DD MMM YYYY`,
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <CircularProgress />
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                {activeTab === 1 && (
                  <div id="tab-1" className="tab-content">
                    <div
                      style={{
                        overflow: "scroll",
                        maxHeight: "350px",
                        width: "100%",
                        minHeight: "auto",
                        paddingBottom: "70px",
                      }}
                      class="list"
                      id="style-1"
                    >
                      {filteredCustomers && filteredCustomers?.length > 0 ? (
                        filteredCustomers.map((customer, i) => {
                          const customerTemplates = templates?.filter(
                            (item) => item.customer === customer._id
                          );
                          if (
                            customerTemplates?.length > 0 &&
                            filteredTemplates?.filter(
                              (item) => item.customer === customer._id
                            ).length > 0
                          ) {
                            console.log(filteredTemplates);
                            return (
                              <div>
                                <Accordion
                                  expanded={expanded === i}
                                  onChange={handleChangeAccordion(i)}
                                >
                                  <AccordionSummary
                                    style={{
                                      marginLeft: "12px",
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                  >
                                    <Avatar
                                      style={{
                                        maxWidth: "32px",
                                        maxHeight: "32px",
                                        fontSize: "14px",
                                      }}
                                      src={customer.profilePicture}
                                    >
                                      {getInitials(
                                        `${customer.firstName} ${customer.lastName}`
                                      )}
                                    </Avatar>
                                    <Typography
                                      style={{
                                        fontSize: 15,
                                        flexBasis: "50%",
                                        flexShrink: 0,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {customer.firstName} {customer.lastName}
                                    </Typography>
                                    <Typography
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {
                                        filteredTemplates?.filter(
                                          (item) =>
                                            item.customer === customer._id
                                        ).length
                                      }{" "}
                                      templates
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {filteredTemplates ? (
                                      filteredTemplates.map((v) => {
                                        if (v.customer === customer._id) {
                                          return (
                                            <li
                                              className={
                                                v._id === selectedTemplate &&
                                                "choosenTemplate"
                                              }
                                              key={v._id}
                                              onClick={() =>
                                                handleChange(v._id)
                                              }
                                            >
                                              <div
                                                className="user-wrapper"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "60%",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    <div className="nameGroup">
                                                      <div
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {v.name || v.title}
                                                      </div>
                                                      <div
                                                        style={{
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                        }}
                                                      >
                                                        {v.name
                                                          ? v.title
                                                          : v.subtitle}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      width: "35%",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "80px",
                                                      }}
                                                    >
                                                      {convertirTemps(
                                                        v.totalTime
                                                      )}
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          fontSize: "12px",
                                                          color: "#939393",
                                                        }}
                                                      >
                                                        Date de création
                                                      </div>
                                                      <div
                                                        style={{
                                                          fonstSize: "14px",
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        {moment(
                                                          v.createdAt
                                                        ).calendar({
                                                          sameDay: `[Aujourd'hui]`,
                                                          nextDay: `[Demain]`,
                                                          nextWeek: `dddd`,
                                                          lastDay: `[Hier]`,
                                                          lastWeek: `[Last] dddd`,
                                                          sameElse: `DD MMM YYYY`,
                                                        })}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      })
                                    ) : (
                                      <CircularProgress />
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "10px",
                          }}
                        >
                          <ReportIcon
                            style={{ color: "gray", fontSize: "200px" }}
                          />
                          <p>
                            Aucune templates n'a été assignés à des
                            utilisateurs.
                          </p>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div id="tab-1" className="tab-content">
                    <div
                      class="list"
                      id="style-1"
                      style={{ paddingBottom: "70px" }}
                    >
                      {templates?.findIndex(
                        (v) => v.customer === props.selectedCustomer._id
                      ) >= 0 ? (
                        <div>
                          {templates ? (
                            filteredTemplates.map((v) => {
                              if (v.customer === props.selectedCustomer._id) {
                                return (
                                  <li
                                    className={
                                      v._id === selectedTemplate &&
                                      "choosenTemplate"
                                    }
                                    key={v._id}
                                    onClick={() => handleChange(v._id)}
                                  >
                                    <div
                                      className="user-wrapper"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "60%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <div className="nameGroup">
                                            <div
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {v.name || v.title}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {v.name ? v.title : v.subtitle}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "35%",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ maxWidth: "80px" }}>
                                            {convertirTemps(v.totalTime)}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "#939393",
                                              }}
                                            >
                                              Date de création
                                            </div>
                                            <div
                                              style={{
                                                fonstSize: "14px",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {moment(v.createdAt).calendar({
                                                sameDay: `[Aujourd'hui]`,
                                                nextDay: `[Demain]`,
                                                nextWeek: `dddd`,
                                                lastDay: `[Hier]`,
                                                lastWeek: `[Last] dddd`,
                                                sameElse: `DD MMM YYYY`,
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            })
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      ) : (
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <ReportIcon
                            style={{ color: "gray", fontSize: "200px" }}
                          />
                          <p>
                            Vous n'avez pas encore de templates assignés à{" "}
                            {props.selectedCustomer.firstName}.
                          </p>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="trainingBtngroup"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              background: "white",
              width: "100%",
            }}
          >
            {disabled ? (
              <Button id="delete-off" disabled color="primary">
                Supprimer
              </Button>
            ) : (
              <Button
                id="delete-on"
                onClick={onDelete}
                disabled={disabled}
                color="secondary"
              >
                Supprimer
              </Button>
            )}
            <Button id="cancel" onClick={handleClose}>
              Annuler
            </Button>
            {disabled ? (
              <Button id="confirm-off" disabled color="primary">
                Importer
              </Button>
            ) : (
              <Button id="confirm-on" onClick={onSelect} color="primary">
                Importer
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const ExerciceList = (props) => {
  const { handleClose, open } = props;
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState([]);
  const [filterEquipement, setFilterEquipement] = useState([]);
  const [filterMuscle, setFilterMuscle] = useState([]);
  const [selectedExercices, setSelectedExercices] = useState([]);
  const [unilateralNb, setUnilateralNb] = useState(0);

  const onValidate = () => {
    props.onSelect(selectedExercices);
    props.handleClose();
    setSelectedExercices([]);
  };

  const [combinedFilters, setCombinedFilters] = useState([]);
  useEffect(() => {
    setCombinedFilters([...filterType, ...filterEquipement, ...filterMuscle]);
  }, [filterType, filterEquipement, filterMuscle]);

  const handleFilterTypeCheck = (value) => (e) => {
    if (e.target.checked) {
      setFilterType([...filterType, value]);
    } else {
      if (filterType.length == 2) {
        if (filterType.indexOf("Repos") != -1) {
          filterType.shift();
        }
      }
      setFilterType(filterType.filter((v) => v !== value));
    }
  };
  const handleFilterEquipementCheck = (value) => (e) => {
    if (e.target.checked) {
      setFilterEquipement([...filterEquipement, value]);
    } else {
      setFilterEquipement(filterEquipement.filter((v) => v !== value));
    }
  };
  const handleFilterMuscleCheck = (value) => (e) => {
    if (e.target.checked) {
      setFilterMuscle([...filterMuscle, value]);
    } else {
      setFilterMuscle(filterMuscle.filter((v) => v !== value));
    }
  };

  console.log(props.exercices);

  const filteredExercices = props.exercices.slice(1).filter((v) => {
    let result = true;
    if (search) {
      let txt = new RegExp(search, "i");
      result = v?.name.match(txt);
    }
    if (result && filterType.length) {
      result = filterType.includes(v.type);
    }
    if (result && filterEquipement.length) {
      result = !!filterEquipement.find((f) => v.equipment?.includes(f));
    }
    if (result && filterMuscle.length) {
      result = !!filterMuscle.find((f) => v.muscles?.includes(f));
    }
    return result;
  });

  const reposIndex = selectedExercices.findIndex((element) => {
    return element.name === "Repos";
  });
  const repoExercice = props.exercices[0];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopup = Boolean(anchorEl);
  let disabled = !selectedExercices || selectedExercices === "none";
  const [activeTab, setActiveTab] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleClosePopup = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleClickPopup = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const flushModalData = () => {
    setSelectedExercices([]);
  };

  const handleDeleteChip = (valueToDelete) => () => {
    setCombinedFilters((prevCombined) =>
      prevCombined.filter((value) => value !== valueToDelete)
    );
    setFilterType((prevFilters) =>
      prevFilters.filter((value) => value !== valueToDelete)
    );
    setFilterEquipement((prevFilters) =>
      prevFilters.filter((value) => value !== valueToDelete)
    );
    setFilterMuscle((prevFilters) =>
      prevFilters.filter((value) => value !== valueToDelete)
    );
  };
  return (
    <Dialog className="filterModal exerciceAdd" open={open}>
      <div className="trainingModal">
        <div className="traningBox">
          <div
            style={{
              background: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "25px",
              padding: "0px 28px",
            }}
          >
            <Typography className="title-modal">Ajouter un exercice</Typography>
            <CancelIcon
              className="optionAudio"
              style={{ color: "#838383", margin: "2px" }}
              onClick={() => {
                handleClose();
                flushModalData();
              }}
              fontSize="small"
            />
          </div>
          <div className="flex" style={{ maxWidth: "100%", width: "100%" }}>
            <div className="trainingTabs">
              <ul className="trainingTabs-nav">
                <li
                  className={activeTab === 0 ? "trainingTab-active" : ""}
                  onClick={() => handleTabClick(0)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="selectExo">
                      <a>Rechercher</a>
                    </div>
                    {activeTab === 0 && (
                      <span
                        style={{
                          maxWidth: 70,
                          width: "100%",
                          height: "1px",

                          backgroundColor: "#3888FF",
                          marginBottom: "20px",
                        }}
                      />
                    )}
                  </div>
                </li>
                <li
                  className={activeTab === 1 ? "trainingTab-active" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "15px",
                      alignItems: "center",
                    }}
                  >
                    <div className="selectExo">
                      <a>Sélectionnés</a>
                    </div>
                    {activeTab === 1 && (
                      <span
                        style={{
                          maxWidth: 70,
                          width: "100%",
                          height: "1px",

                          backgroundColor: "#3888FF",
                          marginBottom: "20px",
                        }}
                      />
                    )}
                  </div>
                  {selectedExercices.length !== 0 && (
                    <p className="selectExo-counter">
                      {selectedExercices.length}
                    </p>
                  )}
                </li>
              </ul>
              <div className="tabs-stage">
                {activeTab === 0 && (
                  <div id="tab-0" className="tab-content">
                    <div class="list">
                      <ul>
                        <DialogContent style={{ padding: 0 }}>
                          <div className="optionsTab-search">
                            <TextField
                              style={{
                                marginTop: "1px",
                                backgroundColor: "white",
                              }}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    style={{ paddingLeft: "10px" }}
                                    position="start"
                                  >
                                    <SvgIcon
                                      fontSize="small"
                                      style={{ color: "#3888FF" }}
                                    >
                                      <SearchIcon />
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Rechercher"
                              variant="outlined"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              autoFocus
                            />
                            <div style={{ marginTop: 10 }}>
                              <div className="optionsfilter-wrap">
                                <div
                                  className={openPopup && "focus"}
                                  onClick={handleClickPopup}
                                >
                                  {combinedFilters.length ? (
                                    <div>
                                      {combinedFilters.map((value) => (
                                        <Chip
                                          key={value}
                                          label={value}
                                          onDelete={handleDeleteChip(value)}
                                          deleteIcon={
                                            <CloseIcon
                                              style={{ color: "white" }}
                                            />
                                          }
                                          style={{
                                            color: "white",
                                            backgroundColor: "#3888FF",
                                            marginRight: "2px",
                                          }}
                                        />
                                      ))}
                                    </div>
                                  ) : (
                                    <p>Sélectionner ...</p>
                                  )}

                                  {openPopup ? (
                                    <ExpandLessIcon></ExpandLessIcon>
                                  ) : (
                                    <ExpandMoreIcon></ExpandMoreIcon>
                                  )}
                                </div>
                                <Popover
                                  className="optionsfilter-popup"
                                  open={openPopup}
                                  anchorEl={anchorEl}
                                  onClose={handleClosePopup}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <ul>
                                    <li class="optgroup">
                                      <span class="label">Type d'exercice</span>
                                      <ul class="optgroup-col">
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Musculation"
                                              title="Musculation"
                                              checked={combinedFilters.includes(
                                                "Musculation"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Musculation"
                                              )}
                                            />
                                            Musculation
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Cardio"
                                              title="Cardio"
                                              checked={combinedFilters.includes(
                                                "Cardio"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Cardio"
                                              )}
                                            />
                                            Cardio
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Warm-up"
                                              title="Warm-up"
                                              checked={combinedFilters.includes(
                                                "Warm-up"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Warm-up"
                                              )}
                                            />
                                            Warm-up
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Poids de corps"
                                              title="Poids de corps"
                                              checked={combinedFilters.includes(
                                                "Poids de corps"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Poids de corps"
                                              )}
                                            />
                                            Poids de corps
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Cross-Training"
                                              title="Cross-Training"
                                              checked={combinedFilters.includes(
                                                "Cross-Training"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Cross-Training"
                                              )}
                                            />
                                            Cross-Training
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Récupération"
                                              title="Récupération"
                                              checked={combinedFilters.includes(
                                                "Récupération"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Récupération"
                                              )}
                                            />
                                            Récupération
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Mobilité"
                                              title="Mobilité"
                                              checked={combinedFilters.includes(
                                                "Mobilité"
                                              )}
                                              onChange={handleFilterTypeCheck(
                                                "Mobilité"
                                              )}
                                            />
                                            Mobilité
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                    <li class="optgroup">
                                      <span class="label">Équipement</span>
                                      <ul class="optgroup-col">
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Haltères"
                                              title="Haltères"
                                              checked={combinedFilters.includes(
                                                "Haltères"
                                              )}
                                              onChange={handleFilterEquipementCheck(
                                                "Haltères"
                                              )}
                                            />
                                            Haltères
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Kettlebell"
                                              title="Kettlebell"
                                              checked={combinedFilters.includes(
                                                "Kettlebell"
                                              )}
                                              onChange={handleFilterEquipementCheck(
                                                "Kettlebell"
                                              )}
                                            />
                                            Kettlebell
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Barre"
                                              title="Barre"
                                              checked={combinedFilters.includes(
                                                "Barre"
                                              )}
                                              onChange={handleFilterEquipementCheck(
                                                "Barre"
                                              )}
                                            />
                                            Barre
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Banc"
                                              title="Banc"
                                              checked={combinedFilters.includes(
                                                "Banc"
                                              )}
                                              onChange={handleFilterEquipementCheck(
                                                "Banc"
                                              )}
                                            />
                                            Banc
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Élastique"
                                              title="Élastique"
                                              checked={combinedFilters.includes(
                                                "Élastique"
                                              )}
                                              onChange={handleFilterEquipementCheck(
                                                "Élastique"
                                              )}
                                            />
                                            Élastique
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                    <li class="optgroup">
                                      <span class="label">Muscle</span>
                                      <ul class="optgroup-col">
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Épaules"
                                              title="Épaules"
                                              checked={combinedFilters.includes(
                                                "Épaules"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Épaules"
                                              )}
                                            />
                                            Épaules
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Biceps"
                                              title="Biceps"
                                              checked={combinedFilters.includes(
                                                "Biceps"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Biceps"
                                              )}
                                            />
                                            Biceps
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Quadriceps"
                                              title="Quadriceps"
                                              checked={combinedFilters.includes(
                                                "Quadriceps"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Quadriceps"
                                              )}
                                            />
                                            Quadriceps
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Mollets"
                                              title="Mollets"
                                              checked={combinedFilters.includes(
                                                "Mollets"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Mollets"
                                              )}
                                            />
                                            Mollets
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Pectoraux"
                                              title="Pectoraux"
                                              checked={combinedFilters.includes(
                                                "Pectoraux"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Pectoraux"
                                              )}
                                            />
                                            Pectoraux
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Triceps"
                                              title="Triceps"
                                              checked={combinedFilters.includes(
                                                "Triceps"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Triceps"
                                              )}
                                            />
                                            Triceps
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Ischios"
                                              title="Ischios"
                                              checked={combinedFilters.includes(
                                                "Ischios"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Ischios"
                                              )}
                                            />
                                            Ischios
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Abdominaux"
                                              title="Abdominaux"
                                              checked={combinedFilters.includes(
                                                "Abdominaux"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Abdominaux"
                                              )}
                                            />
                                            Abdominaux
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Dos"
                                              title="Dos"
                                              checked={combinedFilters.includes(
                                                "Dos"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Dos"
                                              )}
                                            />
                                            Dos
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Avant Bras"
                                              title="Avant Bras"
                                              checked={combinedFilters.includes(
                                                "Avant Bras"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Avant Bras"
                                              )}
                                            />
                                            Avant Bras
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <Checkbox
                                              value="Fessiers"
                                              title="Fessiers"
                                              checked={combinedFilters.includes(
                                                "Fessiers"
                                              )}
                                              onChange={handleFilterMuscleCheck(
                                                "Fessiers"
                                              )}
                                            />
                                            Fessiers
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </Popover>
                                <div
                                  tabIndex="0"
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "0px",
                                    cursor: "pointer",
                                    borderRadius: "0px",
                                    overflow: "hidden",
                                    border: "none",
                                    borderBottom: "2px solid #d1d1d1",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      if (
                                        selectedExercices.findIndex(
                                          (ex) =>
                                            ex?.name === repoExercice?.name
                                        ) >= 0
                                      ) {
                                        setSelectedExercices(
                                          selectedExercices.filter(
                                            (ex) =>
                                              ex?.name !== repoExercice?.name
                                          )
                                        );
                                        let nb = 0;
                                        selectedExercices.forEach((element) => {
                                          if (element["label"]) {
                                            ++nb;
                                          }
                                        });

                                        setUnilateralNb(nb);
                                      } else {
                                        setSelectedExercices([
                                          ...selectedExercices,
                                          {
                                            ...repoExercice,
                                            temps: 0,
                                            reps: 0,
                                            poids: 0,
                                          },
                                        ]);
                                      }
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={repoExercice?.thumbnail}
                                      style={{
                                        height: "100%",
                                        width: "55px",
                                        height: "55px",
                                        objectFit: "cover",
                                        borderRadius: "4px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "10px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        {repoExercice?.name}
                                        <br />
                                      </Typography>
                                    </div>

                                    {reposIndex >= 0 && (
                                      <div
                                        style={{
                                          height: "25px",
                                          width: "24px",
                                          display: "flex",
                                          flexDirection: " column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "sticky",
                                          right: "488px",
                                          bottom: "30px",
                                          borderRadius: "0px 0px 12px 0px",
                                          background: "#4c6eff",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "800",
                                            color: "white",
                                          }}
                                        >
                                          {reposIndex + 1}
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tabResult-search"
                            style={{ paddingBottom: "50px" }}
                          >
                            <div>
                              {filteredExercices.map((v, i) => {
                                const selectedIndex =
                                  selectedExercices.findIndex(
                                    (ex) => ex?.name === v?.name
                                  );
                                const onExerciceClick = () => {
                                  if (selectedIndex >= 0) {
                                    setSelectedExercices(
                                      selectedExercices.filter(
                                        (ex) => ex?.name !== v?.name
                                      )
                                    );
                                    let nb = 0;
                                    selectedExercices.forEach((element) => {
                                      if (element["label"]) {
                                        ++nb;
                                      }
                                    });
                                    if (
                                      selectedExercices[selectedIndex].label
                                    ) {
                                      nb = nb - 2;
                                    }
                                    setUnilateralNb(nb);
                                  } else {
                                    if (v.unilateral) {
                                      setSelectedExercices([
                                        ...selectedExercices,
                                        {
                                          ...v,
                                          temps: 0,
                                          reps: 0,
                                          poids: 0,
                                          mirror: v.mirror ? true : false, //unilateral
                                          label: `${v.unilateral} ${
                                            v.mirror
                                              ? "gauche"
                                              : v.unilateral === "Jambe"
                                              ? "droite"
                                              : "droit"
                                          }`,
                                        },
                                        {
                                          ...v,
                                          temps: 0,
                                          reps: 0,
                                          poids: 0,
                                          mirror: !v.mirror ? true : false, //unilateral
                                          label: `${v.unilateral} ${
                                            !v.mirror
                                              ? "gauche"
                                              : v.unilateral === "Jambe"
                                              ? "droite"
                                              : "droit"
                                          }`,
                                        },
                                      ]);
                                      let nb = unilateralNb + 2;
                                      setUnilateralNb(nb);
                                    } else {
                                      setSelectedExercices([
                                        ...selectedExercices,
                                        {
                                          ...v,
                                          temps: 0,
                                          reps: 0,
                                          poids: 0,
                                        },
                                      ]);
                                    }
                                  }
                                };

                                return (
                                  <React.Fragment key={v._id}>
                                    <div
                                      tabIndex="0"
                                      onClick={onExerciceClick}
                                      onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                          onExerciceClick();
                                        }
                                      }}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#ffffff",

                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        marginBottom: "8px",
                                        cursor: "pointer",

                                        overflow: "hidden",
                                      }}
                                    >
                                      <img
                                        src={v?.thumbnail}
                                        style={{
                                          borderRadius: "4px",
                                          height: "75px",
                                          width: "75px",
                                          objectFit: "cover",
                                        }}
                                      />
                                      <div
                                        style={{
                                          height: "100%",
                                          display: "flex",
                                          flexDirection: "row",
                                          padding: "10px",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            color="textPrimary"
                                            variant="h4"
                                            style={{
                                              fontSize: "16px",
                                            }}
                                          >
                                            {v?.name}
                                            <br />
                                            <Typography
                                              gutterBottom
                                              variant="body1"
                                              style={{
                                                color: "grey",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {v.unilateral ? "Unilatéral" : ""}
                                            </Typography>
                                          </Typography>
                                          <Chip
                                            style={{
                                              width: "fit-content",
                                              background: "#efefef",
                                              fontSize: "11px",
                                            }}
                                            size="small"
                                            label={v.type}
                                          />
                                        </div>
                                        <div>
                                          <a
                                            tabIndex="-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={v.preview || v.videoUri}
                                            style={{
                                              fontSize: "16px",
                                              color: "#1c3bbe",
                                            }}
                                          >
                                            Voir la vidéo
                                          </a>
                                        </div>
                                      </div>

                                      {selectedIndex >= 0 && (
                                        <div
                                          style={{
                                            height: "24px",
                                            width: "23px",
                                            display: "flex",
                                            flexDirection: " column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            position: "sticky",
                                            right: "517px",
                                            borderRadius: "0px 0px 12px 0px",
                                            background: "#4c6eff",
                                          }}
                                        >
                                          <Typography
                                            color="textPrimary"
                                            variant="h4"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "800",
                                              color: "white",
                                            }}
                                          >
                                            {selectedIndex + 1}
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </DialogContent>
                      </ul>
                    </div>
                  </div>
                )}
                {activeTab === 1 && (
                  <div id="tab-1" className="tab-content">
                    <div class="list">
                      <ul>
                        <DialogContent className="wrap-search">
                          <div className="optionsTab-search">
                            {selectedExercices.length == 0 ? (
                              <div style={{ marginLeft: "20px" }}>
                                Aucun exercice n'a été sélectionné.
                              </div>
                            ) : (
                              selectedExercices?.map((v, i) => {
                                const handleDelete = (index) => {
                                  setSelectedExercices((prevExercices) => {
                                    const updatedExercices = [...prevExercices];
                                    updatedExercices.splice(index, 1);
                                    return updatedExercices;
                                  });
                                };
                                return (
                                  <div
                                    tabIndex="0"
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#ffffff",

                                      display: "flex",
                                      flexDirection: "row",
                                      marginTop: "10px",
                                      marginBottom: "8px",
                                      cursor: "pointer",

                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      src={v?.thumbnail}
                                      style={{
                                        borderRadius: "4px",
                                        height: "75px",
                                        width: "75px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div
                                      style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "10px",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          color="textPrimary"
                                          variant="h4"
                                          style={{
                                            fontSize: "16px",
                                          }}
                                        >
                                          {v?.name}
                                          <br />
                                          <Typography
                                            gutterBottom
                                            variant="body1"
                                            style={{
                                              color: "grey",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {v?.unilateral ? "Unilatéral" : ""}
                                          </Typography>
                                        </Typography>
                                        <Chip
                                          style={{
                                            width: "fit-content",
                                            background: "#efefef",
                                            fontSize: "11px",
                                          }}
                                          size="small"
                                          label={v?.type}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column-reverse",
                                          alignItems: "center",
                                        }}
                                      >
                                        <a
                                          tabIndex="-1"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={v?.preview || v?.videoUri}
                                          style={{
                                            fontSize: "16px",
                                            color: "#1c3bbe",
                                          }}
                                        >
                                          Voir la vidéo
                                        </a>
                                        <CancelIcon
                                          className="optionAudio"
                                          style={{
                                            color: "#838383",
                                            margin: "2px",
                                          }}
                                          onClick={() => handleDelete(i)}
                                          fontSize="small"
                                        />
                                      </div>
                                    </div>

                                    {selectedIndex >= 0 && (
                                      <div
                                        style={{
                                          height: "24px",
                                          width: "23px",
                                          display: "flex",
                                          flexDirection: " column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "sticky",
                                          right: "517px",
                                          borderRadius: "0px 0px 12px 0px",
                                          background: "#4c6eff",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="h4"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "800",
                                            color: "white",
                                          }}
                                        >
                                          {selectedIndex + 1}
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </DialogContent>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <DialogActions
            style={{ borderTop: "1px solid #efefef", justifyContent: "center" }}
          >
            <div className="trainingBtngroup">
              {selectedExercices.length === 0 ? (
                <Button
                  id="confirm-off"
                  disabled
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                >
                  Ajouter ({selectedExercices.length}) exercices au circuit
                </Button>
              ) : (
                <Button
                  id="confirm-on"
                  onClick={() => {
                    onValidate();
                    setActiveTab(0);
                  }}
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                >
                  Ajouter ({selectedExercices.length}) exercices au circuit
                </Button>
              )}
            </div>
          </DialogActions> */}
        </div>
        <div
          className="trainingBtngroup"
          style={{
            position: "absolute",
            bottom: "10px",
            right: "150px",
          }}
        >
          {selectedExercices.length === 0 ? (
            <Button
              id="confirm-off"
              disabled
              color="primary"
              endIcon={<ArrowForwardIcon />}
            >
              Ajouter ({selectedExercices.length}) exercices au circuit
            </Button>
          ) : (
            <Button
              id="confirm-on"
              onClick={() => {
                onValidate();
                setActiveTab(0);
              }}
              color="primary"
              endIcon={<ArrowForwardIcon />}
            >
              Ajouter ({selectedExercices.length}) exercices au circuit
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const propsStep = {
  temps: {
    step: 15,
    max: 120,
  },
  reps: {
    step: 1,
    max: 20,
  },
  poids: {
    step: 1,
    max: 40,
  },
};

const ExerciceForm = (props) => {
  const { exercices } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exerciceAnchorEl, setExerciceAnchorEl] = React.useState(null);
  const circuitExerciceCheck = props.exerciceCheck[props.circuitIndex] || [];

  const handleChipClick = (exIndex) => (event) => {
    setAnchorEl(event.currentTarget);
    setExerciceAnchorEl(exIndex);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setExerciceAnchorEl(null);
  };

  const afficherCote = (label) => {
    if (label && label.includes("gauche")) {
      return "(G)";
    } else if (label?.includes("droite") || label?.includes("droit")) {
      return "(D)";
    } else {
      return "";
    }
  };

  const updateOtherSide = (exercices, i) => {
    if (exercices[i]?.label?.includes("gauche")) {
      if (
        exercices[i - 1]?.label?.includes("droite") ||
        exercices[i - 1]?.label?.includes("droit")
      ) {
        exercices[i - 1].reps = exercices[i].reps;
        exercices[i - 1].poids = exercices[i].poids;
        exercices[i - 1].temps = exercices[i].temps;
        exercices[i - 1].tempMinutes = exercices[i].tempMinutes;
        exercices[i - 1].tempSecondes = exercices[i].tempSecondes;

        return exercices;
      }
    } else if (
      exercices[i]?.label?.includes("droite") ||
      exercices[i]?.label?.includes("droit")
    ) {
      if (exercices[i + 1]?.label?.includes("gauche")) {
        exercices[i + 1].reps = exercices[i].reps;
        exercices[i + 1].poids = exercices[i].poids;
        exercices[i + 1].temps = exercices[i].temps;
        exercices[i + 1].tempMinutes = exercices[i].tempMinutes;
        exercices[i + 1].tempSecondes = exercices[i].tempSecondes;

        return exercices;
      }
    }
  };

  const handleExerciceCheck = (value) => (e) => {
    //if (e.target.checked) {
    //   const index = exerciceCheck.findIndex((v) => v._id === value._id);
    //   if (index === -1) {
    //     setExerciceCheck([...exerciceCheck, value]);
    //   } else {
    //     const updatedList = [...exerciceCheck];
    //     updatedList[index] = value;
    //     setExerciceCheck(updatedList);
    //   }
    // } else {
    //   setExerciceCheck(exerciceCheck.filter((v) => v._id !== value._id));
    // }
    //console.log([...exerciceCheck, value]);

    if (e.target.checked) {
      props.setExerciceCheck({
        ...props.exerciceCheck,
        [props.circuitIndex]: [
          ...circuitExerciceCheck?.filter((v) => v !== value),
          value,
        ],
      });
    } else {
      props.setExerciceCheck({
        ...props.exerciceCheck,
        [props.circuitIndex]: circuitExerciceCheck?.filter((v) => v !== value),
      });
    }
  };

  return exercices.map((exercice, i) => (
    <div
      key={i}
      id="cardPlanTraining"
      style={{
        width: "100%",
        backgroundColor: "white",
        minHeight: "70px",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      <Checkbox
        tabIndex="-1"
        className="checboxExercice"
        style={{
          position: "absolute",
          zIndex: "1",
          padding: "0",
          borderRadius: "0",
        }}
        checked={circuitExerciceCheck?.includes(i)}
        onChange={handleExerciceCheck(i)}
        checkedIcon={
          // <CheckIcon
          //   style={{
          //     width: "16px",
          //     height: "16px",
          //     background: "#3888FF",
          //     color: "white",
          //     margin: "3px 0 0 3px",
          //     borderRadius: "4px",
          //   }}
          // />
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: "#3888ff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3px",
              margin: "3px 0 0 3px",
            }}
          >
            <span
              style={{ color: "white", fontSize: "12px", fontWeight: "600" }}
            >
              {(circuitExerciceCheck?.findIndex((v) => v === i) || 0) + 1}
            </span>
          </div>
        }
      ></Checkbox>
      <img
        src={exercice?.thumbnail}
        style={{
          height: "100%",
          width: 75,
          objectFit: "cover",
          transform: exercice.mirror ? "scaleX(-1)" : "scaleX(1)",
        }}
      />
      <div
        style={{
          height: 70,
          display: "flex",
          flexDirection: "column",
          padding: "7px 10px 10px 10px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            style={{ fontSize: "13px" }}
          >
            {`${exercice?.name}`}
            <span style={{ color: "grey", fontSize: "13px" }}>
              {afficherCote(exercice?.label)}
            </span>
          </Typography>
          <IconButton
            tabIndex="-1"
            disabled={props.isEnable}
            size="small"
            onClick={() => {
              props.onExercicesChange(
                exercices.filter((v, index) => index !== i)
              );
              props.setExerciceCheck({
                ...props.exerciceCheck,
                [props.circuitIndex]: circuitExerciceCheck
                  ?.filter((index) => index !== i)
                  .map((index) => (index > i ? index - 1 : index)),
              });
            }}
          >
            <CancelIcon fontSize="small" style={{ color: "#9D9D9D" }} />
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          {exercice?.name !== "Repos" && (
            <div style={{ display: "flex", width: "35%" }}>
              <TextField
                style={{ marginRight: "5px" }}
                min="0"
                max="999"
                placeholder="Reps"
                type="number"
                size="small"
                InputProps={{
                  classes: {
                    input: props.classes.exerciceNumberInput,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: props.classes.smallLabel,
                  },
                }}
                value={exercice.reps || ""}
                onChange={(e) => {
                  let newExercices = _.cloneDeep(exercices);

                  newExercices[i]["tempMinutes"] = 0;
                  newExercices[i]["tempSecondes"] = 0;
                  newExercices[i]["temps"] = 0;
                  newExercices[i]["reps"] = e.target.value ? e.target.value : 0;

                  //For unilateral exercices we need to update the other side
                  updateOtherSide(newExercices, i);

                  props.onExercicesChange(newExercices);
                }}
              />
              <TextField
                min="0"
                max="999"
                placeholder="Poids"
                type="number"
                size="small"
                InputProps={{
                  classes: {
                    input: props.classes.exerciceNumberInput,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: props.classes.smallLabel,
                  },
                }}
                value={exercice.poids || ""}
                onChange={(e) => {
                  let newExercices = _.cloneDeep(exercices);

                  newExercices[i]["poids"] = e.target.value;

                  updateOtherSide(newExercices, i);

                  props.onExercicesChange(newExercices);
                }}
                style={{ marginRight: "6px" }}
              />
            </div>
          )}
          {/* <TextField
            label="Temps"
            placeholder="Temps"
            type="time"
            size="small"
            InputProps={{
              classes: {
                input: props.classes.exerciceTimeInput,
              },
            }}
            InputLabelProps={{
              classes: {
                root: props.classes.timeLabel,
                focused: props.classes.timeLabelFocused
              }
            }}
            onChange={(e) => {
              console.log(e)
              console.log(e.target.value)
            }}
          /> */}
          <Chip
            tabIndex="-1"
            id="temps"
            size="small"
            icon={<AddIcon style={{ width: "13px" }} />}
            label={
              exercice.temps
                ? `${new Date(exercice.temps * 1000)
                    .toISOString()
                    .substr(14, 5)}`
                : "Temps"
            }
            clickable
            onClick={handleChipClick(i)}
            color={"primary"}
            style={{
              fontSize: "0.7rem",
              backgroundColor: exercice.temps ? "#197BD0" : "#767676",
            }}
          />

          {exercice?.audio ? (
            <AudioPlayer
              tabIndex="-1"
              src={exercice.audio}
              openAudioModal={() => [
                props.openAudioModal(props.circuitIndex, i),
                props.setIntroDialogAudio(false),
                props.setAudioModify(exercice.audio),
              ]}
              deleteAudio={() => props.handleDeleteAudio(props.circuitIndex, i)}
            />
          ) : (
            <IconButton
              tabIndex="-1"
              style={{ background: "#b9b9b9", marginLeft: "15px" }}
              size="small"
              onClick={() => [
                props.openAudioModal(props.circuitIndex, i),
                props.setIntroDialogAudio(false),
                props.setAudioModify(null),
              ]}
            >
              <MicIcon style={{ color: "#f4f6f8" }} fontSize="small" />
            </IconButton>
          )}

          {/* <Chip
            id="reps"
            size="small"
            icon={<AddIcon />}
            label={exercice.reps ? `${exercice.reps} reps` : 'Reps'}
            clickable
            onClick={handleChipClick(i)}
            color={'primary'}
            style={{ marginRight: '10px', marginBottom: '10px', backgroundColor: exercice.reps ? '#D06D19' : '#767676' }}
          />
          <Chip
            id="poids"
            size="small"
            icon={<AddIcon />}
            label={exercice.poids ? `${exercice.poids} kg` : 'Poids'}
            clickable
            onClick={handleChipClick(i)}
            color={'primary'}
            style={{ marginRight: '10px', marginBottom: '10px', backgroundColor: exercice.poids ? '#4caa3e' : '#767676' }}
          /> */}
          <Popover
            open={exerciceAnchorEl === i ? open : false}
            anchorEl={exerciceAnchorEl === i ? anchorEl : null}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {anchorEl && anchorEl.id === "temps" ? (
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  style={{
                    fontSize: "16px",
                    paddingTop: "2vh",
                    textAlign: "center",
                  }}
                >
                  Minutes
                </Typography>
                <div
                  style={{
                    padding: "15px",
                    width: "250px",
                    height: "90px",
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "-10%",
                  }}
                >
                  <Slider
                    disabled={props.isEnable}
                    defaultValue={
                      Math.floor(exercice.temps / 60)
                        ? Math.floor(exercice.temps / 60)
                        : 0
                    }
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={55}
                    onChangeCommitted={(e, value) => {
                      let newExercices = _.cloneDeep(exercices);
                      newExercices = newExercices.map((exercice) => {
                        const tempMinutes = Math.floor(exercice.temps / 60);
                        const tempSecondes = exercice.temps % 60;
                        return { ...exercice, tempMinutes, tempSecondes };
                      });
                      newExercices[i]["reps"] = 0;
                      newExercices[i]["tempMinutes"] = value ? value : 0;
                      updateOtherSide(newExercices, i);
                      props.onExercicesChange(newExercices);
                    }}
                  />
                </div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  style={{
                    fontSize: "16px",
                    paddingTop: "2vh",
                    textAlign: "center",
                  }}
                >
                  Secondes
                </Typography>
                <div
                  style={{
                    padding: "15px",
                    width: "250px",
                    height: "90px",
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "-10%",
                  }}
                >
                  <Slider
                    disabled={props.isEnable}
                    defaultValue={exercice.temps % 60 ? exercice.temps % 60 : 0}
                    valueLabelDisplay="auto"
                    step={5}
                    marks
                    min={0}
                    max={55}
                    onChangeCommitted={(e, value) => {
                      let newExercices = _.cloneDeep(exercices);
                      newExercices = newExercices.map((exercice) => {
                        const tempMinutes = Math.floor(exercice.temps / 60);
                        const tempSecondes = exercice.temps % 60;
                        return { ...exercice, tempMinutes, tempSecondes };
                      });
                      newExercices[i]["reps"] = 0;
                      newExercices[i]["tempSecondes"] = value ? value : 0;
                      updateOtherSide(newExercices, i);
                      props.onExercicesChange(newExercices);
                    }}
                  />
                </div>
              </div>
            ) : null}
            {anchorEl && anchorEl.id === "reps" ? (
              <div
                style={{
                  padding: "15px",
                  width: "250px",
                  height: "90px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Slider
                  disabled={props.isEnable}
                  defaultValue={exercice.reps}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  onChangeCommitted={(e, value) => {
                    let newExercices = _.cloneDeep(exercices);

                    newExercices[i]["tempMinutes"] = 0;
                    newExercices[i]["tempSecondes"] = 0;
                    newExercices[i]["temps"] = 0;
                    newExercices[i]["reps"] = value ? value : 0;

                    updateOtherSide(newExercices, i);

                    props.onExercicesChange(newExercices);
                  }}
                />
              </div>
            ) : null}
            {anchorEl && anchorEl.id === "poids" ? (
              <div
                style={{
                  padding: "15px",
                  width: "250px",
                  height: "90px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Slider
                  disabled={props.isEnable}
                  defaultValue={0}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={200}
                  onChangeCommitted={(e, value) => {
                    let newExercices = _.cloneDeep(exercices);

                    newExercices[i]["poids"] = value;

                    updateOtherSide(newExercices, i);

                    props.onExercicesChange(newExercices);
                  }}
                />
              </div>
            ) : null}
          </Popover>
        </div>
      </div>
    </div>
  ));
};

import "react-toastify/dist/ReactToastify.css";

const ExportTemplateModal = ({
  handleClose,
  open,
  selectedCustomer,
  exportTemplate,
  values,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [customerId, setCustomerId] = useState(selectedCustomer._id);
  const [myTempcheck, setMyTempcheck] = useState(false);
  const error = templateName.length <= 4;

  const handleChecked = (event) => {
    setCustomerId(event.target.checked ? selectedCustomer._id : undefined);
    setMyTempcheck(false);
  };

  const handleMyTempChecked = () => {
    setMyTempcheck(true);
    setCustomerId(undefined);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="trainingModal">
        <div className="traningBox">
          <p className="pl-15" style={{ fontWeight: "500" }}>
            Enregistrer en template
          </p>
          <div className="flex" style={{ maxWidth: "100%", width: "100%" }}>
            <div className="trainingTabs" style={{ marginTop: "30px" }}>
              <Grid className="pl-15" item md={10} xs={10}>
                <TextField
                  fullWidth
                  label="Nom de template"
                  name="name"
                  onChange={(e) => setTemplateName(e.target.value)}
                  value={templateName}
                  size="small"
                />

                <FormControl error={error} component="fieldset">
                  <RadioGroup
                    aria-label="templateOptions"
                    name="templateOptions"
                    value={myTempcheck ? "myTemplate" : "customer"}
                  >
                    <FormControlLabel
                      value="customer"
                      control={<Radio />}
                      label={selectedCustomer.firstName}
                      checked={!myTempcheck}
                      onChange={handleChecked}
                    />
                    <FormControlLabel
                      value="myTemplate"
                      control={<Radio />}
                      label="Mes templates"
                      checked={myTempcheck}
                      onChange={handleMyTempChecked}
                    />
                  </RadioGroup>
                  {error ? (
                    <FormHelperText>
                      Inscrivez un nom à votre template
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </div>
          </div>
          <div className="trainingBtngroup">
            <Button id="cancel" onClick={handleClose}>
              Annuler
            </Button>
            <Button
              disabled={error}
              id="confirm-on"
              onClick={() => [
                exportTemplate({
                  ...values,
                  customer: customerId,
                  name: templateName,
                }),
                handleClose(),
              ]}
              color="primary"
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const AudioTemplateModal = ({
  setAudioTemplateDialogOpen,
  open,
  onSave,
  ...props
}) => {
  const [blob, setBlob] = useState(null);
  const [dataBlob, setDataBlob] = useState(null);
  const recorder = useAudioRecorder();
  const [audioTitle, setAudioTitle] = useState("");
  const [libraryLoading, setLibraryLoading] = useState(true);
  const [audioLibrary, setAudioLibrary] = useState([]);
  const audioRecorderRef = useRef(null);

  let error = !(blob || props.audioModify);

  const audioRef = useRef(null);

  const addAudioElement = (blob) => {
    setDataBlob(blob);
    const url = URL.createObjectURL(blob);
    setBlob(url);
  };

  const handleSave = () => {
    if (blob) {
      onSave(
        props.introDialogAudio,
        props.audiotemplateDialogOpen?.circuitIndex,
        props.audiotemplateDialogOpen?.exerciceIndex,
        audioTitle,
        dataBlob
      );
      setAudioTemplateDialogOpen(null);
    } else if (props.audioModify) {
      let newValues = _.cloneDeep(props.values);
      newValues.circuits[props.audiotemplateDialogOpen?.circuitIndex].exercices[
        props.audiotemplateDialogOpen?.exerciceIndex
      ].audio = props.audioModify;
      props.setValues(newValues);
      props.setModified(true);
    }
  };
  const onClose = () => {
    setAudioTemplateDialogOpen(null);
  };

  useEffect(() => {
    if (recorder.recordingTime > 59) {
      recorder.stopRecording();
    }
  }, [recorder.recordingTime]);

  const getAudioLibrary = async (exerciceName) => {
    try {
      setLibraryLoading(true);
      const res = await axios.get(
        `https://online.korper.io/coachs/audios/${exerciceName}`,
        { withCredentials: true }
      );
      if (res.data.success) {
        setAudioLibrary(res.data.audios);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLibraryLoading(false);
    }
  };

  useEffect(() => {
    if (!props.introDialogAudio && props.audiotemplateDialogOpen) {
      let ex =
        props.values.circuits[props.audiotemplateDialogOpen?.circuitIndex]
          .exercices[props.audiotemplateDialogOpen?.exerciceIndex];

      if (ex) {
        getAudioLibrary(ex.name);
      }
    }
  }, [props.audiotemplateDialogOpen]);

  return (
    <Dialog
      maxWidth={props.introDialogAudio ? undefined : "md"}
      fullWidth={!props.introDialogAudio}
      open={open}
    >
      <div className="trainingModal" style={{ display: "flex" }}>
        {!props.introDialogAudio && (
          <div className="audios-library">
            <div className="audios-library-header">
              <h3>
                {
                  props.values.circuits[
                    props.audiotemplateDialogOpen?.circuitIndex
                  ].exercices[props.audiotemplateDialogOpen?.exerciceIndex].name
                }
              </h3>
            </div>
            <div className="audios-library-body">
              {libraryLoading ? (
                <div className="audios-library-loading">
                  <CircularProgress />
                </div>
              ) : !audioLibrary.length ? (
                <div className="audios-library-loading">
                  <span>Aucuns audios sauvegardés</span>
                </div>
              ) : (
                audioLibrary.map((v) => (
                  <div
                    className="audios-library-item"
                    style={{
                      backgroundColor:
                        v.url === props.audioModify ? "#e1e1e1" : undefined,
                    }}
                    onClick={() => props.setAudioModify(v.url)}
                  >
                    <div>
                      <h4>{v.title}</h4>
                    </div>
                    <div className="audios-timestamps">
                      <span>{moment(v.createdAt).format("DD/MM")}</span>
                      <span>00:00</span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
        <div
          className="traningBox"
          style={{ width: props.introDialogAudio ? "100%" : "50%" }}
        >
          <div id="optionsModal" style={{ fontWeight: "500" }}>
            <CancelIcon
              className="closeAudio"
              style={{
                position: "absolute",
                color: "#9D9D9D",
                cursor: "pointer",
                marginLeft: "15px",
                right: 12,
              }}
              onClick={onClose}
              fontSize="small"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {props.audiotemplateDialogOpen?.circuitIndex}
              {props.audiotemplateDialogOpen?.exerciceIndex} */}
              {props.introDialogAudio ? (
                <p id="audio-name">Intro -{props.values.title}</p>
              ) : (
                <TextField
                  id="audio-name"
                  placeholder="Ajouter un titre"
                  value={audioTitle}
                  onChange={(e) => setAudioTitle(e.target.value)}
                />
              )}
            </div>

            {/* <>
              <p style={{ display: 'flex', justifyContent: 'center',fontWeight:400,color:"#cacaca",marginTop:"55px" }}>
                Cliquez sur le bouton pour démarrer l'enregistrement
              </p>
            </> */}
          </div>
          <div className="flex" style={{ maxWidth: "100%", width: "100%" }}>
            <div
              className="trainingTabs"
              style={{ marginTop: "30px", height: "200px" }}
            >
              <Grid>
                <div
                  id="audio"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AudioRecorder
                    onRecordingComplete={addAudioElement}
                    recorderControls={recorder}
                    downloadFileExtension="mp3"
                  />

                  {recorder?.mediaRecorder && (
                    <LiveAudioVisualizer
                      mediaRecorder={recorder.mediaRecorder}
                      width={200}
                      height={75}
                    />
                  )}
                  {props.audioModify && !recorder?.mediaRecorder ? (
                    <audio
                      src={props.audioModify}
                      controls={true}
                      type="audio/mp3"
                      id="audiorecorded"
                    />
                  ) : (
                    blob &&
                    !recorder?.mediaRecorder && (
                      <audio
                        ref={audioRef}
                        src={blob}
                        controls={true}
                        type="audio/mp3"
                        id="audiorecorded"
                      />
                    )
                  )}
                </div>
              </Grid>
            </div>
          </div>
          <div
            className="trainingBtngroup"
            style={{ borderTop: "1px solid #D6D6D6" }}
          >
            <Button
              disabled={error}
              id="confirm-on"
              onClick={() => [setAudioTemplateDialogOpen(null), handleSave()]}
              color="primary"
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

let statetest = false;
const TrainingProgramForm = ({
  className,
  selectedYear,
  selectedWeek,
  selectedDay,
  weekTrainings,
  onSubmit,
  onDelete,
  exportTemplate,
  selectedCustomer,
  selectedToolbarDay,
  setSelectedToolbarDay,
  trainings,
  getTrainingsWeek,
  ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState(weekTrainings[selectedDay]);
  const [modified, setModified] = useState(false);
  const [circuitExerciceDialogOpen, setCircuitExerciceDialogOpen] =
    useState(null);
  const [circuitExerciceDialogOpens, setCircuitExerciceDialogOpens] =
    useState(null);
  const [exportedtemplateDialogOpen, setExportedTemplateDialogOpen] =
    useState(false);

  const [audioModify, setAudioModify] = useState(null);
  const [audiotemplateDialogOpen, setAudioTemplateDialogOpen] = useState(null);
  const [introDialogAudio, setIntroDialogAudio] = useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [dialogTrainingOpen, setdialogTrainingOpen] = useState(false);
  const [analysisOpen, setAnalysisOpen] = useState(false);
  const [exerciceCheck, setExerciceCheck] = useState({});
  const previous = usePrevious({ selectedWeek, selectedCustomer });

  let { missedExercices, exercicePerformances } = useMemo(() => {
    const data = weekTrainings[selectedDay];

    let missedExercices = [];
    let exercicePerformances = [];

    if (!data || !data.done) {
      return { missedExercices, exercicePerformances };
    }

    let index = 0;

    for (const circuit of data.circuits) {
      for (const exercice of circuit.exercices) {
        if (data.doneExercices && !data.doneExercices.includes(index)) {
          missedExercices.push(exercice.name);
        }

        if (data.performances) {
          if (data.performances[index]) {
            exercicePerformances.push({
              name: exercice.name,
              reps: data.performances[index].reps
                ? {
                    new: data.performances[index].reps,
                    old: exercice.reps,
                  }
                : undefined,
              poids: data.performances[index].poids
                ? {
                    new: data.performances[index].poids,
                    old: exercice.poids,
                  }
                : undefined,
              distance: data.performances[index].distance
                ? {
                    new: data.performances[index].distance,
                    old: exercice.distance,
                  }
                : undefined,
            });
          }
        }
        index++;
      }
    }

    return { missedExercices, exercicePerformances };
  }, [weekTrainings, selectedDay]);

  const handleSaveAudio = async (
    introDialogAudio,
    circuitIndex,
    exerciceIndex,
    audioTitle,
    dataBlob
  ) => {
    try {
      var newValues = _.cloneDeep(values);
      var exercice = introDialogAudio
        ? null
        : newValues.circuits[circuitIndex].exercices[exerciceIndex];
      var file = new File([dataBlob], "audio.mp3");
      const data = new FormData();
      data.append("audio", file);
      data.append(
        "json",
        JSON.stringify({ title: audioTitle, exercice: exercice?.name })
      );

      let response = await axios.post(
        `https://online.korper.io/coachs/audios`,
        data,
        { withCredentials: true }
      );

      if (response.data.success) {
        setModified(true);
        if (introDialogAudio === true) {
          newValues.audio = response.data.url;
          setValues(newValues);
        } else {
          exercice.audio = response.data.url;
          setValues(newValues);
        }
      } else {
        console.log(response);
        toast.error("Erreur serveur, impossible d'upload votre audio 1");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, impossible d'upload votre audio ");
    }
  };

  const handleDeleteAudio = (circuitIndex, exerciceIndex) => {
    var newValues = _.cloneDeep(values);
    if (introDialogAudio === true) {
      newValues.audio = undefined;
      setValues(newValues);
      setIntroDialogAudio(false);
      setModified(true);
    } else {
      newValues.circuits[circuitIndex].exercices[exerciceIndex].audio =
        undefined;
      setValues(newValues);
      setModified(true);
    }
  };

  const handleDeleteIntroAudio = () => {
    var newValues = _.cloneDeep(values);
    newValues.audio = undefined;
    setValues(newValues);
    setIntroDialogAudio(false);
  };

  const handleChange = (event) => {
    changeValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      previous?.selectedWeek !== selectedWeek ||
      previous?.selectedCustomer !== selectedCustomer
    ) {
      setModified(false);
    }
  }, [selectedYear, selectedWeek, selectedCustomer]);

  useEffect(() => {
    if (!modified) {
      setValues(weekTrainings[selectedDay]);
    }
  }, [weekTrainings]);

  const changeValues = (v) => {
    setValues(v);
    setModified(true);
  };

  const submit = (v, id) => {
    onSubmit(v, id, () => setModified(false));
  };

  const deleteTraining = (v, id) => {
    setModified(false);
    onDelete(v, id);
  };

  const TrainingList = (props) => {
    const { handleClose, open } = props;
    const [searchtr, setSearchtr] = useState("");
    const weeks = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
    const months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Jui",
      "Aou",
      "Sept",
      "Oct",
      "Nov",
      "Déc",
    ];
    const mois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    const getDate = (date) => {
      let day = weeks[date.getDay()];
      let month = months[date.getMonth()];
      let dayNb = date.getDate();
      return (
        <span>
          {" "}
          {day} {dayNb} {month}{" "}
        </span>
      );
    };
    const daysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };
    const getWeek = (date) => {
      let today = new Date(date);
      let dayNumber = today.getDay();
      let begin = today.getDate() - dayNumber;
      let last = begin + 6;
      let month = today.getMonth();
      if (last > daysInMonth(today.getMonth(), today.getFullYear())) {
        last = last - daysInMonth(today.getMonth(), today.getFullYear());
        month = month + 1;
      }
      if (month >= 11) {
        month = 0;
      }
      if (begin == 0)
        begin = daysInMonth(today.getMonth() - 1, today.getFullYear());
      return (
        <div
          style={{
            padding: "10px",
            height: "100%",
            fontWeight: "600",
            justifyContent: "center",
          }}
        >
          {begin} - {last} {mois[month]}
        </div>
      );
    };

    const closeTraining = () => {
      props.handleClose();
    };
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle style={{ fontSize: "20px" }}>
          Copier un entraînement passé
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              closeTraining();
            }}
            color="primary"
            style={{ marginTop: "-12%" }}
          >
            x
          </Button>
        </DialogActions>
        <DialogContent style={{ width: 590, height: 590, paddingBottom: "5%" }}>
          <TextField
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Recherche"
            variant="outlined"
            value={searchtr}
            onChange={(e) => setSearchtr(e.target.value)}
          />
          <div style={{ marginTop: 10 }}></div>
          <React.Fragment>
            {trainings
              .filter((train) => train.length !== 0)
              .map((pastWeekTrain, index) => {
                try {
                  return (
                    <div key={index}>
                      {getWeek(new Date(pastWeekTrain[0]["date"]["time"]))}
                      {pastWeekTrain
                        .filter((u) => {
                          let result = true;
                          if (searchtr) {
                            let txt = new RegExp(searchtr, "i");
                            result = u.title.match(txt);
                          }
                          return result;
                        })
                        .map((v, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                props.onSelects({
                                  v,
                                });
                              }}
                              className="card"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "15%",
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`https://online.korper.io/files${v.image}`}
                                  style={{
                                    height: "100px",
                                    width: "80px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "10px",
                                  height: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="body2"
                                  style={{ fontSize: "15px" }}
                                >
                                  {v["date"]
                                    ? getDate(new Date(v["date"]["time"]))
                                    : "-"}
                                </Typography>
                              </div>
                              <p
                                style={{
                                  borderRight: "1px solid #bbb",
                                  height: "90%",
                                  marginTop: "1%",
                                }}
                              ></p>
                              <div
                                style={{
                                  width: "45%",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "10px",
                                  height: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="body2"
                                  style={{ fontSize: "15px" }}
                                >
                                  {v.title}
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="body2"
                                  style={{ fontSize: "12px" }}
                                >
                                  {v.subtitle}
                                </Typography>
                              </div>
                              <p
                                style={{
                                  borderRight: "1px solid #bbb",
                                  height: "90%",
                                  marginTop: "1%",
                                }}
                              ></p>
                              <div
                                style={{
                                  width: "15%",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "10px",
                                  height: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="body2"
                                  style={{ fontSize: "16px" }}
                                >
                                  {Math.round(v.totalTime / 60)} Min
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                } catch (err) {
                  console.error(err);
                  return "";
                }
              })}
            <Divider style={{ marginTop: "8px" }} />
          </React.Fragment>
        </DialogContent>
      </Dialog>
    );
  };

  const copyCircuit = (v) => {
    let tmp = [];
    let index = 0;
    while (values.circuits[index]) {
      if (v == index) {
        tmp.push(values.circuits[index]);
        tmp.push({
          name: values.circuits[v]["name"], //`Circuit ${values.circuits.length + 1}`,
          exercices: _.cloneDeep(values.circuits[v]["exercices"]),
          time: values.circuits[v]["time"],
          cal: values.circuits[v]["cal"],
        });
      } else {
        tmp.push(values.circuits[index]);
      }
      ++index;
    }
    changeValues({
      ...values,
      circuits: [...tmp],
    });
  };

  const selectedDate = moment()
    .isoWeekYear(selectedYear)
    .isoWeek(selectedWeek)
    .isoWeekday(selectedDay);
  const weeks = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const disabled = selectedDate.isBefore(moment(), "day") || values?.done;

  const onTemplateImport = (template) => {
    let newCircuits = template.circuits.map((circuit) => {
      circuit.cal = 0;
      let previousMet = metTable["Repos"];

      circuit.exercices = circuit.exercices.map((exercice) => {
        const tempMinutes = Math.floor(exercice.temps / 60);
        const tempSecondes = exercice.temps % 60;

        let cal = 0;

        if (exercice.type === "Repos") {
          cal =
            ((previousMet * selectedCustomer.weight) / 60 / 60) *
            (exercice.temps + exercice.reps * 2);
          circuit.cal += cal;
        } else if (metTable[exercice.type]) {
          cal =
            ((metTable[exercice.type] * selectedCustomer.weight) / 60 / 60) *
            (exercice.temps + exercice.reps * 2);
          circuit.cal += cal;
        }
        previousMet = metTable[exercice.type];

        return { ...exercice, tempMinutes, tempSecondes, cal };
      });
      return circuit;
    });

    changeValues({
      ...values,
      placeholder: template.title,
      // title: template.title,
      // subtitle: template.subtitle,
      equipment: template.equipment,
      circuits: newCircuits,
      image: template.image,
    });
  };

  const formatSeconds = (seconds) => {
    return `${`0${Math.floor(seconds / 60)}`.slice(-2)}:${`0${
      seconds % 60
    }`.slice(-2)}`;
  };

  const calculatorTimeCal = (v) => {
    return `${Math.floor(v.time / 60)} min - ${Math.round(v.cal) || 0} cal`;
  };

  if (!values) {
    return (
      <Card
        className={clsx(classes.root, className)}
        style={{
          backgroundColor:
            selectedDay === selectedToolbarDay ? "#f1f1f1" : "#cdcdcd",
        }} //"#efefef"
        // {...rest}
      >
        <PerfectScrollbar>
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ textAlign: "center", paddingTop: 60 }}
                >
                  <Typography variant="h5">
                    {selectedDate.format("dddd DD MMMM YYYY")}
                  </Typography>
                  {selectedCustomer && (
                    <Typography
                      color="textSecondary"
                      variant="h2"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#8B8B8B",
                        marginTop: "8px",
                      }}
                    >
                      Jour de récupération
                    </Typography>
                  )}
                </Grid>
                {selectedCustomer && !disabled && (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      className="buttonAddTraining"
                      style={{
                        backgroundColor: "#ffffff",
                        textTransform: "none",
                      }}
                      disabled={selectedDate.isBefore(moment(), "day")}
                      onClick={() => {
                        if (
                          !selectedCustomer.subscriptionStatus ||
                          selectedCustomer.subscriptionStatus === "none"
                        ) {
                          alert(`Ce client n'a pas d'abonnement Korper actif`);
                        } else {
                          let i = trainingImages.length - 1;
                          let rd = Math.floor(Math.random() * (i + 1));
                          changeValues({
                            title: "",
                            subtitle: "",
                            equipment: [],
                            circuits: [],
                            image: trainingImages[rd],
                          });
                        }

                        setSelectedToolbarDay(selectedDate.weekday() + 1);
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Nouvel entrainement
                    </Button>
                    <Button
                      className="buttonAddTraining"
                      style={{
                        marginTop: "55px",
                        backgroundColor: "#c6e5ff",
                        textTransform: "none",
                      }}
                      disabled={selectedDate.isBefore(moment(), "day")}
                      onClick={async () => {
                        if (
                          !selectedCustomer.subscriptionStatus ||
                          selectedCustomer.subscriptionStatus === "none"
                        ) {
                          alert(`Ce client n'a pas d'abonnement Korper actif`);
                        } else {
                          let training;
                          if (selectedWeek == 1) {
                            training = await getTrainingsWeek(
                              selectedCustomer,
                              selectedYear - 1,
                              weeksInYear(selectedYear - 1)
                            ); //week -1
                          } else {
                            training = await getTrainingsWeek(
                              selectedCustomer,
                              selectedYear,
                              selectedWeek - 1
                            );
                          }
                          try {
                            if (training.length) {
                              let counter = 0;
                              training.forEach((element) => {
                                if (element.date.day == selectedDay) {
                                  Swal.fire({
                                    text: `Voulez-vous également copier les audios ?`,
                                    confirmButtonText: "Oui",
                                    showDenyButton: true,
                                    denyButtonText: "Non",
                                  }).then((result) => {
                                    try {
                                      element["done"] = null;
                                      element["doneDate"] = null;
                                      element["doneExercices"] = null;
                                      element["doneTime"] = null;
                                      element["totalTime"] = null;
                                      element["totalCalories"] = null;
                                      element["date"] = null;
                                      element["caloriesBurnt"] = null;
                                      element["difficulty"] = null;
                                      element["message"] = null;
                                      element["_id"] = null;
                                      element["label"] = null;
                                      element["mirror"] = null;
                                      element[
                                        "placeholder"
                                      ] = `${element["title"]}`;
                                      element["subtitle"] = null;

                                      if (!result.isConfirmed) {
                                        element["audio"] = null;
                                        element["circuits"].forEach(
                                          (circuit) => {
                                            circuit["exercices"].forEach(
                                              (exercice) => {
                                                exercice["audio"] = null;
                                              }
                                            );
                                          }
                                        );
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                    changeValues(element);
                                  });
                                } else {
                                  counter++;
                                }
                              });
                              if (counter === training.length) {
                                Swal.fire({
                                  text: `Aucun entraînement trouvé la semaine passée pour le ${
                                    weeks[selectedDay - 1]
                                  }`,
                                  confirmButtonText: "Ok",
                                });
                              }
                            } else {
                              Swal.fire({
                                text: `Aucun entraînement trouvé la semaine passée pour le ${
                                  weeks[selectedDay - 1]
                                }`,
                                confirmButtonText: "Ok",
                              });
                            }
                          } catch (err) {}
                        }
                        setSelectedToolbarDay(selectedDate.weekday() + 1);
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Entrainement de {weeks[selectedDay - 1]} dernier
                    </Button>
                    <Button
                      className="buttonAddTraining"
                      style={{
                        marginTop: "55px",
                        backgroundColor: "#c5ffcb",
                        textTransform: "none",
                      }}
                      disabled={selectedDate.isBefore(moment(), "day")}
                      onClick={() => [
                        setdialogTrainingOpen(true),
                        setSelectedToolbarDay(selectedDate.weekday() + 1),
                      ]}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Ajouter un entraînement planifié
                    </Button>
                    <TrainingList
                      open={dialogTrainingOpen}
                      handleClose={() => {
                        setdialogTrainingOpen(false);
                      }}
                      onSelects={(r) => {
                        //r is a training
                        let res = _.cloneDeep(r.v);
                        setdialogTrainingOpen(false);
                        Swal.fire({
                          text: `Voulez-vous également copier les audios ?`,
                          confirmButtonText: "Oui",
                          showDenyButton: true,
                          denyButtonText: "Non",
                        }).then((result) => {
                          try {
                            res["done"] = null;
                            res["doneDate"] = null;
                            res["doneExercices"] = null;
                            res["doneTime"] = null;
                            res["totalCalories"] = null;
                            res["date"] = null;
                            res["caloriesBurnt"] = null;
                            res["difficulty"] = null;
                            res["message"] = null;
                            res["label"] = null;
                            res["mirror"] = null;
                            res["_id"] = null;
                            res["placeholder"] = `${res["title"]}`;
                            res["subtitle"] = null;

                            if (!result.isConfirmed) {
                              res["audio"] = null;
                              res["circuits"].forEach((circuit) => {
                                circuit["exercices"].forEach((exercice) => {
                                  exercice["audio"] = null;
                                });
                              });
                            }
                          } catch (err) {}
                          changeValues(res); // make sure to exit after
                        });
                      }}
                    />
                    <Button
                      className="buttonAddTraining"
                      style={{
                        marginTop: "55px",
                        backgroundColor: "#fff7c4",
                        textTransform: "none",
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => [
                        setTemplateDialogOpen(true),
                        setSelectedToolbarDay(selectedDate.weekday() + 1),
                      ]}
                    >
                      Ajouter un template
                    </Button>
                    <TemplateModal
                      selectedCustomer={selectedCustomer}
                      open={templateDialogOpen}
                      handleClose={() => setTemplateDialogOpen(false)}
                      onImport={onTemplateImport}
                      students={rest.students}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </CardContent>
        </PerfectScrollbar>
      </Card>
    );
  }

  let totalCalories = 0;

  let totalTime = values.circuits.reduce((acc, curr) => {
    // return acc + curr.exercices.reduce((a, c) => a + c.temps + (c.reps * 2) + 10, 0)
    totalCalories += curr.cal || 0;
    return acc + curr.time;
  }, 0);

  totalCalories = Math.round(totalCalories);

  const getUpdateExercicesPerformances = async () => {
    try {
      let response = await axios.post(
        `https://online.korper.io/coachs/trainings/performances/${selectedCustomer._id}`,
        {
          training: values,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        console.log(response.data.training);
        setValues(response.data.training);
      } else {
        toast.error("Erreur serveur, impossible de récupérer les performances");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, impossible de récupérer les performances");
    }
  };

  return (
    <Card
      id="CardFullTraining"
      className={clsx(classes.root, className)}
      // {...rest}
    >
      <Prompt
        when={modified}
        message="Les modifications apportées ne seront pas enregistrées. Êtes-vous sûr de vouloir quitter la page ?"
      />
      {!modified && values._id && !values.available && (
        <div className="banner" style={{ backgroundColor: "#ffc04c" }}>
          {/* <span>Brouillon</span> */}
        </div>
      )}
      {modified && (
        <div className="banner" style={{ backgroundColor: "#ff8a8a" }}>
          {/* <span>Changements non-sauvegardés</span> */}
        </div>
      )}
      <PerfectScrollbar>
        <CardContent style={{ padding: "0px" }}>
          <Box>
            <Grid container spacing={2}>
              <div style={{ padding: "16px", width: "100%" }}>
                {false && <div className={classes.formDimmer} />}
                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginRight: "15px",
                      }}
                    >
                      <Typography
                        color="textSecondary"
                        variant="h2"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#8B8B8B",
                        }}
                      >
                        ENTRAINEMENT
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#8B8B8B",
                        }}
                        variant="h5"
                      >
                        {selectedDate.format("dddd DD MMM").toUpperCase()}
                      </Typography>
                    </div>
                    <IconButton
                      style={{
                        height: "26px",
                        width: "26px",
                        backgroundColor: "#3f51b5",
                      }}
                      variant="outlined"
                      onClick={() => setExportedTemplateDialogOpen(true)}
                    >
                      <SaveIcon
                        fontSize="small"
                        style={{
                          color: "white",
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </IconButton>
                    <ExportTemplateModal
                      selectedCustomer={selectedCustomer}
                      open={exportedtemplateDialogOpen}
                      handleClose={() => setExportedTemplateDialogOpen(false)}
                      exportTemplate={exportTemplate}
                      values={values}
                    />
                  </div>
                </Grid>
                <Grid id="titleInput" item md={12} xs={12}>
                  <TextField
                    style={{ padding: "5px 0px 15px 0px" }}
                    disabled={selectedDate.isBefore(moment(), "day")}
                    fullWidth
                    placeholder={values.placeholder || "Titre"}
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    size="small"
                  />
                </Grid>
                <Grid id="titleInput" item md={12} xs={12}>
                  <TextField
                    style={{ padding: "5px 0px 15px 0px" }}
                    disabled={selectedDate.isBefore(moment(), "day")}
                    fullWidth
                    placeholder="Sous-titre"
                    name="subtitle"
                    onChange={handleChange}
                    value={values.subtitle}
                    size="small"
                  />
                </Grid>
                {/* <Grid
                item
                md={12}
                xs={12}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small">
                  <InputLabel>Équipement</InputLabel>
                  <Select disabled={selectedDate.isBefore(moment(), 'day')}
                    label="Équipement"
                    name="equipment"
                    onChange={handleChange}
                    value={values.equipment || []}
                    renderValue={(selected) => selected.join(', ')}
                    multiple
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {['Kettlebell', 'Barre', 'Banc', 'Haltères', 'Élastique long', 'Élastique court'].map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox size="small" checked={values.equipment?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
                <Grid item md={12} xs={12}>
                  {/* <Carousel
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={Math.max(
                      trainingImages.indexOf(values.image),
                      0
                    )}
                    onChange={(i) => {
                      if (values?.image !== trainingImages[i]) {
                        changeValues({
                          ...values,
                          image: trainingImages[i],
                        });
                      }
                    }}
                  >
                    {trainingImages.map((v, i) => (
                      <div
                        key={i}
                        style={{ height: "250px", maxHeight: "250px" }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={`https://online.korper.io/files${v}`}
                        />
                      </div>
                    ))}
                  </Carousel> */}
                  <Button
                    variant="outlined"
                    startIcon={<ReplayIcon />}
                    style={{
                      color: "white",
                      border: "none",
                      marginTop: "-130%",
                      marginLeft: "80%",
                    }}
                    onClick={() => {
                      let i = trainingImages.length - 1;
                      let rd = Math.floor(Math.random() * (i + 1));
                      changeValues({
                        ...values,
                        image: trainingImages[rd],
                      });
                    }}
                    size="small"
                    disabled={selectedDate.isBefore(moment(), "day")}
                  ></Button>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography color="textPrimary" variant="body2">
                        Temps total
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                      >
                        {Math.floor(totalTime / 60)} Min
                      </Typography>
                    </div>
                    <div>
                      <Typography color="textPrimary" variant="body2">
                        Calories totales
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                      >
                        {totalCalories} Cal
                      </Typography>
                    </div>
                    {values.audio ? (
                      <AudioPlayer
                        src={values.audio}
                        deleteAudio={() => handleDeleteIntroAudio()}
                      />
                    ) : (
                      <IconButton
                        style={{ background: "red", padding: "3px" }}
                        variant="outlined"
                        onClick={() => [
                          setAudioTemplateDialogOpen(true),
                          setIntroDialogAudio(true),
                        ]}
                      >
                        <MicIcon
                          style={{ color: "#f4f6f8" }}
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                  </div>
                </Grid>
              </div>
              {values.circuits.length > 0 && (
                <Button
                  variant="outlined"
                  startIcon={<UpdateIcon />}
                  style={{
                    border: "none",
                    borderTop: "1px solid #E6E6E6",
                    borderRadius: "0px",
                    width: "100%",
                    color: "#818181",
                  }}
                  onClick={getUpdateExercicesPerformances}
                  size="small"
                >
                  Mettre à jour les perfs
                </Button>
              )}
              {values.circuits.map((v, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      padding: "0px 10px 0px 16px",
                      width: "100%",
                      background: "#b7b7b7",
                    }}
                  >
                    <Grid
                      id="circuitBloc"
                      item
                      md={12}
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        disabled={selectedDate.isBefore(moment(), "day")}
                        onClick={() => {
                          changeValues({
                            ...values,
                            circuits: values.circuits.filter(
                              (v, index) => index !== i
                            ),
                          });
                          setExerciceCheck([]);
                        }}
                        size="small"
                      >
                        <img
                          src="../static/images/cancel.png"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>

                      <TextField
                        disabled={selectedDate.isBefore(moment(), "day")}
                        // fullWidth
                        style={{
                          width: "88%",
                          padding: "5px 0px 15px 0px",
                          fontWeight: "600",
                        }}
                        placeholder="Nom du circuit"
                        name="circuitName"
                        onChange={(event) => {
                          let newValues = _.cloneDeep(values);
                          newValues.circuits[i].name = event.target.value;
                          changeValues(newValues);
                        }}
                        value={`${v?.name}`}
                        size="small"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                        style={{ color: "#ebebeb", marginBottom: "" }}
                      >
                        {calculatorTimeCal(v)}
                      </Typography>
                    </Grid>
                  </div>
                  <ExerciceForm
                    setIntroDialogAudio={setIntroDialogAudio}
                    handleDeleteAudio={handleDeleteAudio}
                    openAudioModal={(circuitIndex, exerciceIndex) =>
                      setAudioTemplateDialogOpen({
                        circuitIndex,
                        exerciceIndex,
                      })
                    }
                    setExerciceCheck={setExerciceCheck}
                    exerciceCheck={exerciceCheck}
                    setAudioModify={setAudioModify}
                    circuitIndex={i}
                    classes={classes}
                    isEnable={selectedDate.isBefore(moment(), "day")}
                    exercices={v.exercices}
                    onExercicesChange={(r) => {
                      let newValues = _.cloneDeep(values);
                      newValues.circuits[i].exercices = r;
                      /*newValues.circuits[i].exercices.forEach(element => {
                      element.temps = (element.tempSecondes?element.tempSecondes:0)+(element.tempMinutes?element.tempMinutes:0);
                    });*/
                      newValues.circuits[i].cal = 0;
                      let previousMet = metTable["Repos"];
                      newValues.circuits[i].time = newValues.circuits[
                        i
                      ].exercices.reduce((a, c, index, array) => {
                        if (
                          c.temps !== 0 &&
                          c.tempSecondes == true &&
                          c.tempMinutes == true
                        ) {
                          c.tempMinutes = Math.floor(c.temps / 60)
                            ? Math.floor(c.temps / 60)
                            : 0;
                          c.tempSecondes = c.temps % 60;
                        } else if (!c.tempSecondes && !c.tempMinutes) {
                          c.tempMinutes = Math.floor(c.temps / 60)
                            ? Math.floor(c.temps / 60)
                            : 0;
                          c.tempSecondes = c.temps % 60;
                        } else if (c.tempSecondes === 0) {
                          c.tempSecondes = 0;
                        } else if (c.tempMinutes === 0) {
                          c.tempMinutes = 0;
                        }

                        c.temps =
                          (c.tempSecondes ? c.tempSecondes : 0) +
                          (c.tempMinutes * 60 ? c.tempMinutes * 60 : 0);

                        let result = c.temps + c.reps * 2;

                        if (c.type === "Repos") {
                          c.cal =
                            ((previousMet * selectedCustomer.weight) /
                              60 /
                              60) *
                            result;
                          newValues.circuits[i].cal += c.cal;
                        } else if (metTable[c.type]) {
                          c.cal =
                            ((metTable[c.type] * selectedCustomer.weight) /
                              60 /
                              60) *
                            result;
                          newValues.circuits[i].cal += c.cal;
                        }
                        previousMet = metTable[c.type];

                        return a + result + 5; // 5 sec before each exercices
                      }, 0);
                      changeValues(newValues);
                    }}
                  />
                  <Grid
                    className="optionsExercices"
                    md={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {values.circuits[i].exercices?.length > 0 && (
                      <Button
                        tabIndex="-1"
                        className="repsExoBtn"
                        variant="outlined"
                        startIcon={
                          <LibraryAddIcon
                            style={{
                              color: !exerciceCheck[i]?.length
                                ? "#c5c5c5"
                                : "#3888FF",
                            }}
                          />
                        }
                        style={{
                          border: "none",
                          borderRadius: "0px",
                          width: "100%",
                          color: !exerciceCheck[i]?.length
                            ? "#c5c5c5"
                            : "#818181",
                        }}
                        onClick={() => {
                          let newValues = _.cloneDeep(values);
                          let newExercices = exerciceCheck[i].map((index) => {
                            return {
                              ...v.exercices[index],
                              audio: undefined,
                              _id: undefined,
                            };
                          });
                          let initialExerciceSize =
                            newValues.circuits[i].exercices?.length;
                          newValues.circuits[i].exercices = newValues.circuits[
                            i
                          ].exercices.concat(newExercices.filter((ex) => !!ex));

                          newValues.circuits[i].cal = 0;
                          let previousMet = metTable["Repos"];
                          newValues.circuits[i].time = newValues.circuits[
                            i
                          ].exercices.reduce((a, c, index, array) => {
                            if (
                              c.temps !== 0 &&
                              c.tempSecondes == true &&
                              c.tempMinutes == true
                            ) {
                              c.tempMinutes = Math.floor(c.temps / 60)
                                ? Math.floor(c.temps / 60)
                                : 0;
                              c.tempSecondes = c.temps % 60;
                            } else if (!c.tempSecondes && !c.tempMinutes) {
                              c.tempMinutes = Math.floor(c.temps / 60)
                                ? Math.floor(c.temps / 60)
                                : 0;
                              c.tempSecondes = c.temps % 60;
                            } else if (c.tempSecondes === 0) {
                              c.tempSecondes = 0;
                            } else if (c.tempMinutes === 0) {
                              c.tempMinutes = 0;
                            }

                            c.temps =
                              (c.tempSecondes ? c.tempSecondes : 0) +
                              (c.tempMinutes * 60 ? c.tempMinutes * 60 : 0);

                            let result = c.temps + c.reps * 2;

                            if (c.type === "Repos") {
                              c.cal =
                                ((previousMet * selectedCustomer.weight) /
                                  60 /
                                  60) *
                                result;
                              newValues.circuits[i].cal += c.cal;
                            } else if (metTable[c.type]) {
                              c.cal =
                                ((metTable[c.type] * selectedCustomer.weight) /
                                  60 /
                                  60) *
                                result;
                              newValues.circuits[i].cal += c.cal;
                            }
                            previousMet = metTable[c.type];

                            return a + result + 5; // 5 sec before each exercices
                          }, 0);

                          changeValues(newValues);

                          let newExerciceCheckList = Array(newExercices.length)
                            .fill(null)
                            .map((el, idx) => idx + initialExerciceSize);

                          setExerciceCheck({
                            ...exerciceCheck,
                            [i]: newExerciceCheckList,
                          });
                        }}
                        size="small"
                        disabled={!exerciceCheck[i]?.length}
                      >
                        Ajouter une série
                      </Button>
                    )}

                    <Button
                      tabIndex="-1"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{
                        border: "none",
                        borderTop: "1px solid #E6E6E6",
                        borderRadius: "0px",
                        width: "100%",
                        color: "#818181",
                      }}
                      onClick={() => setCircuitExerciceDialogOpen(i)}
                      size="small"
                      disabled={selectedDate.isBefore(moment(), "day")}
                    >
                      Ajouter un exercice
                    </Button>

                    <Button
                      tabIndex="-1"
                      style={{
                        border: "none",
                        borderTop: "1px solid #E6E6E6",
                        borderRadius: "0px",
                        width: "100%",
                        color: "#818181",
                      }}
                      disabled={selectedDate.isBefore(moment(), "day")}
                      onClick={() => {
                        let index = 0;
                        let tmp = [];
                        while (values.circuits[index]) {
                          if (index == i) {
                            let tmparr = {
                              name: `Circuit ${values.circuits.length + 1}`,
                              exercices: [],
                              time: 0,
                            };
                            tmp.push(values.circuits[index]);
                            tmp.push(tmparr);
                          } else {
                            tmp.push(values.circuits[index]);
                          }
                          ++index;
                        }
                        return changeValues({
                          ...values,
                          circuits: [...tmp],
                        });
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      size="small"
                    >
                      Ajouter un circuit
                    </Button>
                    {/* <Button
                        disabled={selectedDate.isBefore(moment(), "day")}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        style={{ marginBottom: "20px", marginLeft: "10px" }}
                        onClick={() => copyCircuit(i)}
                        size="small"
                      >
                        copier un circuit
                      </Button> */}
                  </Grid>
                </React.Fragment>
              ))}
              {values.circuits.length ? (
                ""
              ) : (
                <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                  <Button
                    disabled={selectedDate.isBefore(moment(), "day")}
                    onClick={() =>
                      changeValues({
                        ...values,
                        circuits: [
                          ...values.circuits,
                          {
                            name: `Circuit ${values.circuits.length + 1}`,
                            exercices: [],
                            time: 0,
                          },
                        ],
                      })
                    }
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{ marginBottom: "60px" }}
                  >
                    Ajouter un circuit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
      </PerfectScrollbar>
      {!values._id && !disabled && (
        <div
          id="groupbtn"
          style={{
            marginTop: "-54px",
            backgroundColor: "white",
            padding: "10px",
            position: "relative",
            borderTop: "1px solid #0000001f",
            zIndex: "2",
          }}
        >
          <ButtonGroup color="primary" fullWidth>
            <Button
              style={{ borderRadius: "4px" }}
              onClick={() => {
                Swal.fire({
                  title: "Êtes vous sûr ?",
                  showCancelButton: true,
                  confirmButtonText: "Confirmer",
                  cancelButtonText: "Annuler",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setValues(null);
                    setModified(false);
                  } else if (result.isDenied) {
                  }
                });
              }}
              className={classes.cancelBtn}
              variant="contained"
            >
              Annuler
            </Button>
            <Button
              style={{ borderRadius: "4px" }}
              disabled={selectedDate.isBefore(moment(), "day")}
              onClick={() =>
                submit(
                  {
                    ...values,
                    totalTime,
                    totalCalories,
                    date: {
                      year: selectedYear,
                      week: selectedWeek,
                      day: selectedDay,
                    },
                  },
                  selectedCustomer._id
                )
              }
              color="primary"
              variant="contained"
            >
              Créer
            </Button>
          </ButtonGroup>
        </div>
      )}
      {values._id && !disabled && (
        <div
          id="groupbtn"
          style={{
            marginTop: "-54px",
            backgroundColor: "white",
            padding: "10px",
            position: "relative",
            borderTop: "1px solid #0000001f",
            zIndex: "2",
          }}
        >
          <ButtonGroup color="primary" fullWidth>
            <Button
              style={{ borderRadius: "4px" }}
              disabled={selectedDate.isBefore(moment(), "day")}
              onClick={() => {
                Swal.fire({
                  title: "Êtes vous sûr ?",
                  showCancelButton: true,
                  confirmButtonText: "Confirmer",
                  cancelButtonText: "Annuler",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteTraining(
                      {
                        date: {
                          year: selectedYear,
                          week: selectedWeek,
                          day: selectedDay,
                        },
                      },
                      selectedCustomer._id
                    );
                  } else if (result.isDenied) {
                  }
                });
              }}
              className={classes.deleteBtn}
              variant="contained"
            >
              Supprimer
            </Button>
            <Button
              style={{ borderRadius: "4px" }}
              disabled={selectedDate.isBefore(moment(), "day") || !modified}
              onClick={() =>
                submit(
                  {
                    ...values,
                    totalTime,
                    totalCalories,
                    date: {
                      year: selectedYear,
                      week: selectedWeek,
                      day: selectedDay,
                    },
                  },
                  selectedCustomer._id
                )
              }
              color="primary"
              variant="contained"
            >
              Mettre à jour
            </Button>
          </ButtonGroup>
        </div>
      )}
      {disabled && !values.done ? (
        <Button
          disabled={false}
          variant="contained"
          style={{
            marginTop: "-80px",
            marginLeft: "3%",
            marginRight: "3%",
            width: "94%",
            zIndex: 10,
          }}
        >
          Entraînements manqué
        </Button>
      ) : null}
      {values.done && (
        <Button
          startIcon={<BarChartIcon />}
          color="primary"
          variant="contained"
          style={{
            marginTop: "-80px",
            marginLeft: "3%",
            marginRight: "3%",
            width: "94%",
            zIndex: 10,
          }}
          onClick={() => setAnalysisOpen(true)}
        >
          Voir l'analyse
        </Button>
      )}
      {values.done && (
        <Dialog onClose={() => setAnalysisOpen(false)} open={analysisOpen}>
          <DialogTitle>Analyse de séance</DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "11px",
                fontWeight: "400",
                marginTop: 6,
                textAlign: "center",
              }}
            >
              {values.doneDate
                ? moment(values.doneDate).format("DD/MM/YYYY - HH:mm")
                : "..."}
            </span>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              {missedExercices.length ? (
                <div
                  style={{
                    width: exercicePerformances.length ? "50%" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    borderTop: "solid 1px #707070",
                    marginTop: 6,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "#9F9F9F",
                      marginBottom: 5,
                    }}
                  >
                    IGNORÉ
                  </span>
                  {missedExercices.map((v, i) => (
                    <span
                      key={i}
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        marginBottom: 5,
                      }}
                    >
                      {v}
                    </span>
                  ))}
                </div>
              ) : null}
              {exercicePerformances.length ? (
                <div
                  style={{
                    width: missedExercices.length ? "50%" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    borderTop: "solid 1px #707070",
                    marginTop: 6,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderLeftWidth: missedExercices.length ? 1 : 0,
                    borderLeftColor: "#707070",
                    borderLeftStyle: "solid",
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "#9F9F9F",
                      marginBottom: 5,
                    }}
                  >
                    MODIFIÉ
                  </span>
                  {exercicePerformances.map((v, i) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "120px",
                        }}
                      >
                        {v.name}
                      </span>
                      <div
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {v.reps && (
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              marginBottom: 3,
                            }}
                          >
                            <span style={{ color: "grey" }}>{v.reps.old}</span>{" "}
                            {"->"} {v.reps.new} reps
                          </span>
                        )}
                        {v.poids && (
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              marginBottom: 3,
                            }}
                          >
                            <span style={{ color: "grey" }}>{v.poids.old}</span>{" "}
                            {"->"} {v.poids.new} kg
                          </span>
                        )}
                        {v.distance && (
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "600",
                              marginBottom: 3,
                            }}
                          >
                            <span style={{ color: "grey" }}>
                              {v.distance.old}
                            </span>{" "}
                            {"->"} {v.distance.new} km
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderTop: "solid 1px #707070",
                borderBottom: "solid 1px #707070",
                marginTop: 6,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "19px", fontWeight: "400" }}>
                  {formatSeconds(values.doneTime)}
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#9F9F9F",
                  }}
                >
                  Temps
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "19px", fontWeight: "400" }}>-</span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#9F9F9F",
                  }}
                >
                  BPM Max
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "19px", fontWeight: "400" }}>
                  {values.caloriesBurnt || "-"}
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#9F9F9F",
                  }}
                >
                  Cal
                </span>
              </div>
            </div>
            {values.difficulty !== undefined && (
              <>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  {
                    [
                      "Aucune difficulté choisie",
                      "1 - Très facile",
                      "2 - Facile",
                      "3 - Assez facile",
                      "4 - Peu éprouvant",
                      "5 - Modéré",
                      "6 - Intense",
                      "7 - Assez difficile",
                      "8 - Difficile",
                      "9 - Très difficile",
                      "10 - Trop difficile ",
                    ][values.difficulty]
                  }
                </span>
                <div className="progress-container">
                  <div
                    style={{
                      width: `${((values.difficulty + 1) / 10) * 100}%`,
                    }}
                  />
                </div>
              </>
            )}
            {values.textReview && (
              <div
                style={{
                  width: "100%",
                  borderTop: "solid 1px #E0E0E0",
                  marginTop: "20px",
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "14px", fontWeight: "600" }}>
                  Ressenti
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  {values.textReview}
                </span>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAnalysisOpen(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>
      )}
      <ExerciceList
        open={circuitExerciceDialogOpen !== null}
        handleClose={() => setCircuitExerciceDialogOpen(null)}
        onSelect={(r) => {
          let newValues = _.cloneDeep(values);
          let initialLength =
            newValues.circuits[circuitExerciceDialogOpen].exercices.length;
          newValues.circuits[circuitExerciceDialogOpen].exercices =
            newValues.circuits[circuitExerciceDialogOpen].exercices.concat(r);
          changeValues(newValues);

          setExerciceCheck({
            ...exerciceCheck,
            [circuitExerciceDialogOpen]: Array(r.length)
              .fill(null)
              .map((v, i) => i + initialLength),
          });
        }}
        exercices={rest.exercices}
      />
      {audiotemplateDialogOpen && (
        <AudioTemplateModal
          open={audiotemplateDialogOpen !== null}
          audiotemplateDialogOpen={audiotemplateDialogOpen}
          introDialogAudio={introDialogAudio}
          setAudioTemplateDialogOpen={setAudioTemplateDialogOpen}
          onSave={handleSaveAudio}
          values={values}
          setValues={setValues}
          setModified={setModified}
          audioModify={audioModify}
          setAudioModify={setAudioModify}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    exercices: state.auth.exercices,
    user: state.auth.user,
    students: state.auth.students,
  };
};

export default connect(mapStateToProps, null)(TrainingProgramForm);
